import { LocationMarkerIcon } from "@heroicons/react/solid";

import { Paragraph } from "../../atoms/Typography";

interface DeliveryPortProps {
  portName: string;
  portCode: string;
}

export const DeliveryPort: React.FC<DeliveryPortProps> = ({ portName, portCode }) => {
  return (
    <div>
      <Paragraph size="300" color="text-textIcon-blackSecondary" className="pb-2 uppercase">
        Delivery Port
      </Paragraph>
      <div className="flex gap-3">
        <LocationMarkerIcon className="-ml-0.5 text-textIcon-blackSecondary" width="20px" />
        <Paragraph size="200">
          {portCode} <span className="text-textIcon-blackPrimary">{portName}</span>
        </Paragraph>
      </div>
    </div>
  );
};
