import { Tab as HeadlessTab } from "@headlessui/react";
import { SvgIconComponent } from "@mui/icons-material";
import classnames from "classnames";

import { ButtonTypography, ButtonTypographyProps } from "../../atoms";

export type TabProps = {
  label: string;
  // TODO #12908: remove old type once all icons are migrated to MUI
  LeadingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>> | SvgIconComponent;
  variant: "line" | "pill" | "folder";
  size: "small" | "large";
  borderPosition?: "bottom" | "left" | "right";
  "data-testid"?: string;
};

const getLineBorderClassNames = (borderPosition: TabProps["borderPosition"]) => {
  const positions = {
    bottom: "border-b-2",
    left: "pl-2 border-l-2",
    right: "pr-2 border-r-2",
  };

  return borderPosition ? positions[borderPosition] : "";
};

const getTabSizeStyles = (size: TabProps["size"]) => (size === "small" ? "h-[32px]" : "h-[40px]");

const getLineSizeStyles = (size: TabProps["size"], borderPosition: TabProps["borderPosition"]) => {
  if (borderPosition === "bottom") {
    return "px-[4px]";
  }

  if (borderPosition === "left") {
    return size === "small" ? "pl-[8px] pr-[4px]" : "pl-[16px] pr-[4px]";
  }

  if (borderPosition === "right") {
    return size === "small" ? "pr-[8px] pl-[4px]" : "pr-[16px] pl-[4px]";
  }
};

const getLineBorderPositionStyles = (borderPosition: TabProps["borderPosition"]) => {
  if (borderPosition === "bottom") {
    return "-mb-[1px]";
  }

  if (borderPosition === "left") {
    return "-ml-[1px]";
  }

  if (borderPosition === "right") {
    return "-mr-[1px]";
  }
};

const getPillSizeStyles = (size: TabProps["size"]) =>
  size === "small" ? "px-[12px]" : "px-[16px]";

const getFolderSizeStyles = (size: TabProps["size"]) =>
  size === "small" ? "px-[8px]" : "px-[16px]";

const getIconSizeStyles = (size: TabProps["size"]) =>
  size === "small" ? "w-[16px] h-[16px]" : "w-[20px] h-[20px]";

const getTypographySize = (size: TabProps["size"]): ButtonTypographyProps["size"] =>
  size === "small" ? "200" : "100";

const getTabStyles = (
  type: TabProps["variant"],
  isSelected: boolean,
  borderPosition: TabProps["borderPosition"],
  size: TabProps["size"]
) => {
  const base = classnames(
    "flex items-center gap-2",
    "focus:outline-none focus-visible:ring-2 ring-primaryDefault",
    "whitespace-nowrap",
    {
      "text-textIcon-blackPrimary": isSelected,
      "text-textIcon-blackSecondary hover:text-textIcon-blackPrimary": !isSelected,
    }
  );

  const styles: TypeStyles = {
    line: classnames(
      base,
      getTabSizeStyles(size),
      getLineSizeStyles(size, borderPosition),
      getLineBorderClassNames(borderPosition),
      getLineBorderPositionStyles(borderPosition),
      {
        "border-blue-500": isSelected,
        "border-transparent": !isSelected,
      }
    ),
    pill: classnames(base, getTabSizeStyles(size), getPillSizeStyles(size), "rounded-lg", {
      "bg-primaryDefault text-white": isSelected,
      "bg-transparent": !isSelected,
    }),
    folder: classnames(
      base,
      getTabSizeStyles(size),
      getFolderSizeStyles(size),
      "rounded-t-lg border-1 border-neutral_300 -mb-[1px]",
      {
        "bg-neutral_0 text-textIcon-blackPrimary border-b-0": isSelected,
        "bg-neutral_100 text-textIcon-blackSecondary": !isSelected,
      }
    ),
  };

  return styles[type];
};

export const Tab = ({
  label,
  variant,
  size,
  LeadingIcon,
  borderPosition = "bottom",
  "data-testid": testId,
}: TabProps) => {
  return (
    <HeadlessTab
      as="button"
      className={({ selected }) => getTabStyles(variant, selected, borderPosition, size)}
      data-testid={testId}
    >
      {LeadingIcon && <LeadingIcon className={getIconSizeStyles(size)} />}
      <ButtonTypography size={getTypographySize(size)}>{label}</ButtonTypography>
    </HeadlessTab>
  );
};

type TypeStyles = {
  [key in TabProps["variant"]]: string;
};
