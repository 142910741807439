import { PaperClipIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import { ReactElement } from "react";

import { ProductSku } from "@web/common/models";
import { Label, Paragraph } from "@web/ui";
import { isDefined } from "@web/utils";

import { ProductItem } from "../network/model";
import { ItemTotal } from "./lists";

type Props = {
  productItem: ProductItem;
  replacement?: ProductItem;
  lineThrough?: boolean;
  sideNote?: string;
  sideNoteVariant?: "success" | "danger";
  replacementSideNote?: string;
  replacementSideNoteVariant?: "success" | "danger";
  isReplacementAccepted?: boolean;
  renderRowActions?: () => ReactElement;
  className?: string;
  openProductDetailsModal?: (productSku: ProductSku) => void;
  hasDigitalAssetsColumn?: boolean;
};

const tdStyle = "p-2 px-4 text-left relative";

const ItemTileRow = ({
  productItem,
  lineThrough = false,
  sideNote,
  sideNoteVariant,
  openProductDetailsModal,
  hasDigitalAssetsColumn = false,
  isRemoved,
  renderRowActions,
}: Props & { isRemoved: boolean }) => {
  return (
    <tr
      className={classnames(
        "border bg-neutral_0 rounded shadow",
        isDefined(openProductDetailsModal) ? "cursor-pointer" : "",
        isRemoved ? "bg-neutral_300" : "bg-neutral_0"
      )}
      onClick={() => openProductDetailsModal?.(productItem.skuDetails)}
    >
      <td className={`${tdStyle} rounded-l`}>
        {!!productItem.lineNumber && (
          <Label
            size="200"
            className="absolute right-full top-1/2 w-6 flex items-center justify-center"
            style={{ transform: "translateY(-50%)" }}
          >
            {productItem.lineNumber}
          </Label>
        )}
        <div className="flex flex-col">
          <Label size="200">{productItem.name}</Label>
          {sideNote ? (
            <Paragraph
              size="300"
              color={
                sideNoteVariant === "success"
                  ? "text-successDefault"
                  : sideNoteVariant === "danger"
                  ? "text-dangerDefault"
                  : undefined
              }
              className="pt-1"
            >
              {sideNote}
            </Paragraph>
          ) : (
            !!productItem.impaCode && (
              <Paragraph size="300" color="text-textIcon-blackSecondary" className="pt-1">
                IMPA: {productItem.impaCode}
              </Paragraph>
            )
          )}
        </div>
      </td>
      <td
        className={classnames(tdStyle, {
          "rounded-r": !hasDigitalAssetsColumn,
        })}
      >
        <ItemTotal item={productItem} lineThrough={lineThrough} />
        {!hasDigitalAssetsColumn && renderRowActions && renderRowActions()}
      </td>

      {hasDigitalAssetsColumn && (
        <td className={`${tdStyle} rounded-r`}>
          {productItem.skuDetails.attachments && productItem.skuDetails.attachments.length > 0 && (
            <PaperClipIcon
              className="w-5 text-textIcon-blackSecondary"
              data-testid="ordersTable-itemColumn-attachment"
            />
          )}
          {renderRowActions && renderRowActions()}
        </td>
      )}
    </tr>
  );
};

export const ProductItemsTableRow = ({
  productItem,
  lineThrough = false,
  sideNote,
  sideNoteVariant,
  openProductDetailsModal,
  hasDigitalAssetsColumn = false,
  replacement,
  replacementSideNote,
  replacementSideNoteVariant,
  renderRowActions,
}: Props) => {
  return (
    <>
      <ItemTileRow
        productItem={productItem}
        lineThrough={lineThrough}
        sideNote={sideNote}
        sideNoteVariant={sideNoteVariant}
        openProductDetailsModal={openProductDetailsModal}
        hasDigitalAssetsColumn={hasDigitalAssetsColumn}
        isRemoved={!!replacement}
      />
      {replacement && (
        <ItemTileRow
          productItem={replacement}
          lineThrough={false}
          sideNote={replacementSideNote}
          sideNoteVariant={replacementSideNoteVariant}
          openProductDetailsModal={openProductDetailsModal}
          hasDigitalAssetsColumn={hasDigitalAssetsColumn}
          isRemoved={false}
          renderRowActions={renderRowActions}
        />
      )}
    </>
  );
};
