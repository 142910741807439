const routes = {
  orders: "/",
  orderDetails: "/orderDetails",
  orderSuccess: "/orderSuccess",
  manageVessels: "/manageVessels",
  manageCustomers: "/manageCustomers",
  manageUsers: "/manageUsers",
  manageFleets: "/manageFleets",
  gatherBasket: "/basket",
  noAccess: "/no-access",
  logout: "/logout",
};

export default routes;
