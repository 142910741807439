import { useMemo } from "react";

import { DebuggerMenuWrapper } from "@web/common";

type Props = {
  errorDescription?: string;
  error?: unknown;
};

export const DebuggerMenuController = (props: Props) => {
  const timestamp = useMemo(() => new Date().toISOString(), []);

  return (
    <DebuggerMenuWrapper {...props} timestamp={timestamp} appName="Buyer Portal" appAcronym="BP" />
  );
};
