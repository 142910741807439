import { ChangeType, ProductChange } from "@web/common/network/";
import { Label } from "@web/ui";

import { OrderChangesTable } from "./OrderChangesTable";

interface Props {
  changedItems: ProductChange[];
  changeType: ChangeType;
}

export const SingleChangeType = ({ changeType, changedItems }: Props) => {
  if (changedItems.length === 0) {
    return null;
  }

  return (
    <>
      <Label size="300" className="text-textIcon-whiteDisabled block mt-4">
        {changeType}
      </Label>
      <OrderChangesTable changedItems={changedItems} />
    </>
  );
};
