import { TagIcon } from "@heroicons/react/solid";

import { Paragraph } from "../../atoms/Typography";

interface StorageLabelProps {
  label: string;
}

export const StorageLabel: React.FC<StorageLabelProps> = ({ label }) => {
  return (
    <div className="py-3" data-testid="deliveryDetailsStorageLabel">
      <Paragraph size="300" color="text-textIcon-blackSecondary" className="pb-3">
        STORAGE LABEL
      </Paragraph>
      <div className="flex">
        <TagIcon className="-ml-0.5 text-textIcon-blackSecondary" width="20px" />
        <Paragraph size="200" className="pl-3">
          {label}
        </Paragraph>
      </div>
    </div>
  );
};
