import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { authReducer } from "./reducers/authReducer";
import { systemMessagesReducer } from "./reducers/systemMessagesReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  systemMessages: systemMessagesReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
