import { useCallback, useState } from "react";

import { GatherOutFlow, getGatherOutUrl } from "@web/common";

import { AppVesselToken } from "src/typegens";

import { usePurchaserVesselTokenMutation } from "./usePurchaserVesselTokenMutation";
import { useSystemMessage } from "./useSystemMessage";
import { useVesselConfigurationQuery } from "./useVesselConfiguration";

const VITE_GATHEROUT_URL = import.meta.env.VITE_GATHEROUT_URL as string | undefined;

type Params = {
  vesselId: string;
  gatherOutFlow: GatherOutFlow;
  orderId: string | undefined;
  submitReturnToPath: string;
  cancelReturnToPath: string;
};

type UseGatherOut = {
  isGatherOutAvailable: boolean;
  startGatherOut: () => void;
  isGatherOutStarted: boolean;
};

export const useGatherOut = ({
  vesselId,
  gatherOutFlow,
  orderId,
  submitReturnToPath,
  cancelReturnToPath,
}: Params): UseGatherOut => {
  const [isGatherOutStarted, setHasNavigationToGatherOutStarted] = useState(false);

  const vesselConfigurationQuery = useVesselConfigurationQuery(vesselId);

  const { setSystemMessage } = useSystemMessage();

  const { mutate: getPurchaserVesselToken } = usePurchaserVesselTokenMutation({
    onSuccess: (tokenData: AppVesselToken) => {
      // Make compiler happy about the VITE_GATHEROUT_URL, however this will never be false,
      // since we guard the on the caller level
      if (isGatherOutAvailable) {
        window.location.href = getGatherOutUrl({
          baseUrl: VITE_GATHEROUT_URL,
          vesselToken: tokenData.token,
          gatherOutFlow,
          basketId: orderId,
          submitReturnToUrl: `${window.location.origin}${submitReturnToPath}`,
          cancelReturnToUrl: `${window.location.origin}${cancelReturnToPath}`,
        });
      }
    },
    onError: () => {
      setHasNavigationToGatherOutStarted(false);
      setSystemMessage({
        type: "failure",
        message:
          "Could not obtain Gather Out vessel token. Please try again or contact Customer Support.",
      });
    },
  });

  // TODO #9283: Remove `vesselConfigurationQuery.data?.allow.offline` condition when all vessels are enrolled to the `offline` flow
  const isGatherOutAvailable =
    !!vesselConfigurationQuery.data?.allow.offline && !!VITE_GATHEROUT_URL;

  const startGatherOut = useCallback(() => {
    if (isGatherOutAvailable) {
      setHasNavigationToGatherOutStarted(true);
      getPurchaserVesselToken(vesselId);
      return;
    }
    setSystemMessage({
      type: "failure",
      message: `Could not start the Gather Out. Please contact Customer Support with the following information: Offline ${!!vesselConfigurationQuery
        .data?.allow.offline}, Gather Out URL base ${VITE_GATHEROUT_URL}, Vessel ID ${vesselId}`,
    });
  }, [
    getPurchaserVesselToken,
    isGatherOutAvailable,
    setSystemMessage,
    vesselConfigurationQuery.data?.allow.offline,
    vesselId,
  ]);

  return {
    isGatherOutAvailable,
    startGatherOut,
    isGatherOutStarted,
  };
};
