import copy from "copy-to-clipboard";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { Heading, Paragraph, RegularButton, Strong } from "@web/ui";
import { isError, triggerFileDownload } from "@web/utils";

function isErrorWithStatus<T extends { status: string | number }>(error: unknown): error is T {
  return !!(error as T)?.status;
}

function isErrorWithStack<T extends { stack: unknown }>(error: unknown): error is T {
  return !!(error as T)?.stack;
}

type DebuggerMenuProps = {
  onClose: () => void;
  timestamp: string;
  appName: string;
  appAcronym: string;
  errorDescription?: string;
  error?: unknown;
  debuggerMenuErrors?: Record<string, unknown>;
  renderAdditionalActions?: () => React.ReactNode;
};

const version = (import.meta.env.VITE_GIT_HASH || "") as string;

export const DebuggerMenu = ({
  onClose,
  errorDescription,
  error,
  renderAdditionalActions,
  timestamp,
  debuggerMenuErrors,
  appName,
  appAcronym,
}: DebuggerMenuProps) => {
  const location = useLocation();
  const handleClose = () => {
    onClose();
  };

  const readableLocation = location.pathname + location.search;

  const appStatus = useMemo(() => {
    try {
      return {
        timestamp,
        location,
        version,
        localStorage: Object.keys(localStorage).reduce(
          (ls: { [key: string]: string | null }, key) => {
            ls[key] = localStorage.getItem(key);
            return ls;
          },
          {}
        ),
        sessionStorage: Object.keys(sessionStorage).reduce(
          (ls: { [key: string]: string | null }, key) => {
            ls[key] = sessionStorage.getItem(key);
            return ls;
          },
          {}
        ),
        debuggerMenuErrors,
        error: {
          description: errorDescription,
          ...(isError(error) ? { name: error.name, message: error.message } : {}),
          ...(isErrorWithStatus(error) ? { status: error.status } : {}),
          rawErrorData: typeof error === "object" ? error : JSON.stringify(error),
          stack: isErrorWithStack(error) ? error.stack : undefined,
        },
      };
    } catch (error) {
      // Intentionally return no value
      return {
        error: {
          description: "Error while creating the appStatus data",
          rawErrorData: typeof error === "object" ? error : JSON.stringify(error),
          stack: isErrorWithStack(error) ? error.stack : undefined,
          status: "",
          name: "",
          message: "",
        },
      };
    }
  }, [error, errorDescription, debuggerMenuErrors, location, timestamp]);

  const errorDetails = appStatus.error;

  const stringifiedAppStatus = useMemo(() => {
    try {
      return JSON.stringify(appStatus, null, 2);
    } catch {
      return '{ "error": { "description": "Error while stringifying the appStatus data" }}';
    }
  }, [appStatus]);

  const [isAppStatusCopiedToClipboard, setIsAppStatusCopiedToClipboard] = useState(false);
  const handleCopyAppStatusToClipboard = useCallback(() => {
    if (!stringifiedAppStatus) {
      return;
    }
    copy(stringifiedAppStatus);
    setIsAppStatusCopiedToClipboard(true);
    setTimeout(() => {
      setIsAppStatusCopiedToClipboard(false);
    }, 3000);
  }, [stringifiedAppStatus]);

  const [isAppStatusDownloading, setIsAppStatusDownloading] = useState(false);

  const handleDownloadAppStatus = useCallback(() => {
    if (!stringifiedAppStatus) {
      return;
    }
    const blob = new Blob([stringifiedAppStatus], { type: "text/plain" });
    triggerFileDownload({
      file: blob,
      fileNameWithExtension: `S2S_${appAcronym}_status_${timestamp}.txt`,
    });
    setIsAppStatusDownloading(true);
    setTimeout(() => {
      setIsAppStatusDownloading(false);
    }, 3000);
  }, [stringifiedAppStatus, appAcronym, timestamp]);

  const handleReloadApp = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <div className="fixed right-0 top-0 bottom-0 w-full sm:w-[26rem] bg-[#031c] z-50 flex">
      <div className="m-auto max-h-screen w-full">
        <div className="h-screen max-h-screen px-6.5 py-6.5 overflow-y-auto">
          <Heading size="200" color="text-textIcon-whitePrimary">
            Source2Sea
            <br />
            {appName}
            <br />
            Customer Support Menu
          </Heading>
          <Paragraph size="200" color="text-textIcon-whitePrimary" className="mt-2">
            If you see this menu but were not asked to open it by the Source2Sea Customer Support
            team, <Strong>please close it now</Strong> by pressing the &quot;Close Menu&quot; button
            below:
          </Paragraph>
          <div className="flex justify-center mt-3">
            <RegularButton
              variant="secondary"
              size="large"
              label="Close Menu"
              onClick={handleClose}
            />
          </div>
          <pre className="text-textIcon-whitePrimary whitespace-pre-wrap mt-6">
            <strong>Timestamp:</strong> {appStatus.timestamp}
            <br />
            <strong>Version:</strong> {appStatus.version}
            <br />
            <strong>Location:</strong>
            <br />
            {readableLocation}
            <br />
            <br />
            {!!errorDetails?.description && (
              <>
                <strong>Error description:</strong>
                <br />
                {errorDetails.description}
                <br />
                <br />
              </>
            )}
            {!!errorDetails?.status && (
              <>
                <strong>Error status:</strong> {errorDetails.status}
                <br />
              </>
            )}
            {!!errorDetails?.name && (
              <>
                <strong>Error name:</strong> {errorDetails.name}
                <br />
              </>
            )}
            {!!errorDetails?.message && (
              <>
                <strong>Error message:</strong>
                <br />
                {errorDetails.message}
              </>
            )}
          </pre>

          <Paragraph size="200" color="text-textIcon-whitePrimary" className="my-6">
            Only use the buttons below if directly asked by the Source2Sea Customer Support.
          </Paragraph>

          {!!stringifiedAppStatus && (
            <div className="flex flex-col items-center mb-6 gap-3">
              <RegularButton
                variant="secondary"
                size="small"
                label={
                  isAppStatusCopiedToClipboard
                    ? "Copied to clipboard!"
                    : "Copy app status to clipboard"
                }
                disabled={isAppStatusCopiedToClipboard}
                onClick={handleCopyAppStatusToClipboard}
              />
              <RegularButton
                variant="secondary"
                size="small"
                label="Download app status"
                loading={isAppStatusDownloading}
                onClick={handleDownloadAppStatus}
              />
              <RegularButton
                variant="secondary"
                size="small"
                label="Reload app"
                onClick={handleReloadApp}
              />
            </div>
          )}
          {!!renderAdditionalActions && (
            <div className="flex flex-col items-center gap-3">{renderAdditionalActions()}</div>
          )}
        </div>
      </div>
    </div>
  );
};
