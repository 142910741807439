import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";

import { Input, Label, OptionType, Paragraph, RegularButton, Select, Switch } from "@web/ui";

import { FleetFormType, FleetSchema, StocktakeCurrencyCodesType } from "./schema";

type EditFleetModalBodyProps = {
  onSubmit(data: Partial<FleetFormType>): void;
  permissionsOptions: OptionType[];
  customerOptions: OptionType[];
  stocktakeCurrencyOptions: OptionType<StocktakeCurrencyCodesType>[];
  defaultFormValues: Partial<FleetFormType>;
  isEditing: boolean;
  onCancel: () => void;
};
export const EditFleetModalBody: React.FC<EditFleetModalBodyProps> = ({
  onSubmit,
  permissionsOptions,
  customerOptions,
  stocktakeCurrencyOptions,
  defaultFormValues,
  isEditing,
  onCancel,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFormValues,
    resolver: zodResolver(FleetSchema),
  });
  // @ts-expect-error - "" can't be used to index type, so it's type is `any`; fixing this would require whole form refactor
  const globalError = errors?.[""] as { message: string } | undefined;

  return (
    <div
      data-testid="editFleetModal"
      className="inline-block align-bottom bg-neutral_0 rounded-lg py-4 px-5 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full"
    >
      <div className="sm:flex sm:items-start py-4 pr-4">
        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          <Paragraph size="100">
            {isEditing ? "Update Fleet Information" : "Fleet Information"}
          </Paragraph>
          <div className="mt-2">
            {isEditing && (
              <Paragraph size="200" color="text-textIcon-blackSecondary">
                Change the information of this Fleet
              </Paragraph>
            )}
          </div>
        </div>
      </div>
      <form>
        <div className="flex">
          <div className="flex-1">
            <Controller
              name="fleetName"
              control={control}
              render={({ field }) => (
                <Input
                  label="Fleet Name*"
                  errorMessage={errors.fleetName?.message}
                  {...field}
                  withBorder
                />
              )}
            />
          </div>
          <div className="flex-1 ml-3 w-1/2">
            <Controller
              name="customer"
              control={control}
              render={({ field }) => (
                <Select label="Customer*" options={customerOptions} {...field} />
              )}
            />
          </div>
        </div>
        <div className="flex-1 flex flex-col mt-2">
          <Label size="200" color="text-textIcon-blackSecondary">
            Cash fleet
          </Label>
          <div className="mt-2">
            <Controller
              name="cash"
              control={control}
              render={({ field: { value, ...field } }) => (
                <Switch enabled={!!value} onToggle={field.onChange} {...field} />
              )}
            />
          </div>
        </div>
        <div className="flex mb-4">
          <div className="flex-1">
            <Controller
              name="permissions"
              control={control}
              render={({ field }) => (
                <Select
                  label="Permissions*"
                  options={permissionsOptions}
                  className="pt-4"
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className="flex mb-3">
          <div className="w-1/2">
            <div className="flex-1 flex flex-col mt-1">
              <Label size="200" color="text-textIcon-blackSecondary">
                Create RfQ
              </Label>
              <div className="mt-2">
                <Controller
                  name="allow.createRfq"
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <Switch enabled={!!value} onToggle={field.onChange} {...field} />
                  )}
                />
              </div>
            </div>
            <div className="flex-1 flex flex-col mt-2">
              <Label size="200" color="text-textIcon-blackSecondary">
                FMS Integration
              </Label>
              <div className="mt-2">
                <Controller
                  name="allow.fmsIntegration"
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <Switch enabled={!!value} onToggle={field.onChange} {...field} />
                  )}
                />
              </div>
            </div>
            <div className="flex-1 flex flex-col mt-2">
              <Label size="200" color="text-textIcon-blackSecondary">
                PunchOut Integration
              </Label>
              <div className="mt-2">
                <Controller
                  name="allow.punchOutIntegration"
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <Switch enabled={!!value} onToggle={field.onChange} {...field} />
                  )}
                />
              </div>
            </div>
            <div className="flex-1 flex flex-col mt-2">
              <Label size="200" color="text-textIcon-blackSecondary">
                Allow Supplier to edit orders
              </Label>
              <div className="mt-2">
                <Controller
                  name="allow.editable"
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <Switch enabled={!!value} onToggle={field.onChange} {...field} />
                  )}
                />
              </div>
            </div>
            <div className="flex-1 flex flex-col mt-2">
              <Label size="200" color="text-textIcon-blackSecondary">
                Customer order ID is optional
              </Label>
              <div className="mt-2">
                <Controller
                  name="allow.customerOrderIdOptional"
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <Switch enabled={!!value} onToggle={field.onChange} {...field} />
                  )}
                />
              </div>
            </div>
            <div className="flex-1 flex flex-col mt-2">
              <Label size="200" color="text-textIcon-blackSecondary">
                Allow vessels to reorder orders
              </Label>
              <div className="mt-2">
                <Controller
                  name="allow.reorder"
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <Switch enabled={!!value} onToggle={field.onChange} {...field} />
                  )}
                />
              </div>
            </div>
            <div className="flex-1 flex flex-col mt-2">
              <Label size="200" color="text-textIcon-blackSecondary">
                Offline-first Vessel App
              </Label>
              <div className="mt-2">
                <Controller
                  name="allow.offline"
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <Switch enabled={!!value} onToggle={field.onChange} {...field} />
                  )}
                />
              </div>
            </div>
            <div className="flex-1 flex flex-col mt-2">
              <Label size="200" color="text-textIcon-blackSecondary">
                Enable Offline Drafts
              </Label>
              <div className="mt-2">
                <Controller
                  name="allow.offlineDraft"
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <Switch enabled={!!value} onToggle={field.onChange} {...field} />
                  )}
                />
              </div>
            </div>
            <div className="flex-1 flex flex-col mt-2">
              <Label size="200" color="text-textIcon-blackSecondary">
                Enable Order Extra Items
              </Label>
              <div className="mt-2">
                <Controller
                  name="allow.orderExtraItems"
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <Switch enabled={!!value} onToggle={field.onChange} {...field} />
                  )}
                />
              </div>
            </div>
            <div className="flex-1 flex flex-col mt-2">
              <Label size="200" color="text-textIcon-blackSecondary">
                Enable Financial (Invoice) Operation Integration
              </Label>
              <div className="mt-2">
                <Controller
                  name="allow.foIntegration"
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <Switch enabled={!!value} onToggle={field.onChange} {...field} />
                  )}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1 w-1/2 flex flex-col mt-2">
                <Label size="200" color="text-textIcon-blackSecondary">
                  Enable Stocktaking
                </Label>
                <div className="mt-2">
                  <Controller
                    name="allow.stocktaking"
                    control={control}
                    render={({ field: { value, ...field } }) => (
                      <Switch enabled={!!value} onToggle={field.onChange} {...field} />
                    )}
                  />
                </div>
              </div>
              <div className="flex-1 w-1/2 flex flex-col mt-2">
                <Controller
                  name="stocktakeCurrency"
                  control={control}
                  render={({ field }) => (
                    <Select
                      label="Stocktake Currency"
                      options={stocktakeCurrencyOptions}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex-1 flex flex-col mt-2">
              <Label size="200" color="text-textIcon-blackSecondary">
                Sort line items in Purchase Orders by Category
              </Label>
              <div className="mt-2">
                <Controller
                  name="allow.sortPurchaseOrderLines"
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <Switch enabled={!!value} onToggle={field.onChange} {...field} />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex mb-5">
          <div className="flex-1">
            <Controller
              name="srn"
              control={control}
              render={({ field }) => (
                <Input label="Srn" errorMessage={errors.srn?.message} {...field} withBorder />
              )}
            />
          </div>
        </div>
        {!!globalError?.message && (
          <>
            <hr />
            <div className="text-dangerDefault text-sm my-3">{globalError.message}</div>
          </>
        )}
        <hr />
        <div className="flex items-end justify-end mt-5">
          <div className="ml-auto">
            <RegularButton
              className="ml-auto"
              variant="secondary"
              size="large"
              label="Cancel"
              onClick={() => {
                onCancel();
              }}
            />
          </div>
          <div className="ml-4">
            <RegularButton
              variant="primary"
              size="large"
              label={isEditing ? "Update Fleet" : "Add Fleet"}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
