import { Category } from "@web/models";

export const getNonEmptyCategories = (categories: Category[]) => {
  function filterOutEmptyCategories(children: Category[]): Category[] {
    const nonEmptyCategories: Category[] = [];
    children.forEach((category) => {
      if (category.productsNumber && category.productsNumber > 0) {
        nonEmptyCategories.push({
          ...category,
          children: filterOutEmptyCategories(category.children || []),
        });
      }
    });
    return nonEmptyCategories;
  }
  return filterOutEmptyCategories(categories);
};

export const getCategory = (categoryId: string, categories: Category[]): Category[] => {
  return categories.reduce((prev: Category[], category: Category) => {
    if (category.id === categoryId) {
      return [category];
    }
    if (category.children && prev.length === 0) {
      const child = getCategory(categoryId, category.children);
      if (child.length > prev.length) {
        return [category, ...child];
      }
    }
    return prev;
  }, []);
};

export const findDescendantById = (
  descendantId: string,
  ancestor: Category
): Category | undefined => {
  let foundChild: Category | undefined;
  function searchForChild(childId: string, parent: Category) {
    if (parent.id === childId) {
      foundChild = parent;
      return;
    }
    if (parent.children.length > 0) {
      parent.children.forEach((child) => {
        searchForChild(childId, child);
      });
    }
  }
  searchForChild(descendantId, ancestor);
  return foundChild;
};

export const findHighestCategoryBySelectedId = (array: Category[], id: string): Category | null => {
  const search = (array: Category[], id: string): Category | null => {
    return (
      array.find((element) => {
        if (element.id === id) {
          return true;
        }
        if (element.children && element.children.length > 0) {
          return search(element.children, id) !== null;
        }
        return false;
      }) || null
    );
  };

  return search(array, id);
};
