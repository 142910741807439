/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { AddUserToFleetRequest } from "../models/AddUserToFleetRequest";
import type { ApiListAppCustomer } from "../models/ApiListAppCustomer";
import type { ApiListAppFleet } from "../models/ApiListAppFleet";
import type { ApiListAppProductSku } from "../models/ApiListAppProductSku";
import type { ApiListAppVessel } from "../models/ApiListAppVessel";
import type { AppCustomer } from "../models/AppCustomer";
import type { AppFleet } from "../models/AppFleet";
import type { AppOrderCreationRequest } from "../models/AppOrderCreationRequest";
import type { AppOrderRequisition } from "../models/AppOrderRequisition";
import type { AppOrderRequisitionResponse } from "../models/AppOrderRequisitionResponse";
import type { AppPortsResponse } from "../models/AppPortsResponse";
import type { AppPurchaserConfiguration } from "../models/AppPurchaserConfiguration";
import type { AppPurchaserVesselRequest } from "../models/AppPurchaserVesselRequest";
import type { AppPurchasersFleets } from "../models/AppPurchasersFleets";
import type { AppQuotationApproveRequest } from "../models/AppQuotationApproveRequest";
import type { AppQuotationRejectRequest } from "../models/AppQuotationRejectRequest";
import type { AppRequisitionApproveRequest } from "../models/AppRequisitionApproveRequest";
import type { AppRequisitionRejectRequest } from "../models/AppRequisitionRejectRequest";
import type { AppRequisitionUpdateRequest } from "../models/AppRequisitionUpdateRequest";
import type { AppVessel } from "../models/AppVessel";
import type { AppVesselToken } from "../models/AppVesselToken";
import type { AppVesselTokenShareRequest } from "../models/AppVesselTokenShareRequest";
import type { AppVesselTokenShared } from "../models/AppVesselTokenShared";
import type { DraftCopyRequest } from "../models/DraftCopyRequest";
import type { DraftRequest } from "../models/DraftRequest";
import type { RemoveUserFromFleetRequest } from "../models/RemoveUserFromFleetRequest";
import type { Unit } from "../models/Unit";
import type { User } from "../models/User";

export class MainAppService {
  /**
   * @returns AppVessel OK
   * @throws ApiError
   */
  public static getVessel(
    {
      id,
    }: {
      id: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppVessel> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/vessels/{id}",
      path: {
        id: id,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppVessel OK
   * @throws ApiError
   */
  public static saveOrUpdateVessel(
    {
      id,
      requestBody,
    }: {
      id: string;
      requestBody: AppVessel;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppVessel> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/app/vessels/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static deleteVessel(
    {
      id,
    }: {
      id: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/app/vessels/{id}",
      path: {
        id: id,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppOrderRequisition OK
   * @throws ApiError
   */
  public static updateRequisition(
    {
      requisitionId,
      requestBody,
    }: {
      requisitionId: string;
      requestBody: AppRequisitionUpdateRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppOrderRequisition> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/app/requisitions/{requisitionId}",
      path: {
        requisitionId: requisitionId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppFleet OK
   * @throws ApiError
   */
  public static saveOrUpdateFleet(
    {
      id,
      requestBody,
    }: {
      id: string;
      requestBody: AppFleet;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppFleet> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/app/fleets/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static deleteFleet(
    {
      id,
    }: {
      id: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/app/fleets/{id}",
      path: {
        id: id,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppOrderRequisition OK
   * @throws ApiError
   */
  public static update(
    {
      draftId,
      requestBody,
    }: {
      draftId: string;
      requestBody: DraftRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppOrderRequisition> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/app/drafts/{draftId}",
      path: {
        draftId: draftId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppOrderRequisition OK
   * @throws ApiError
   */
  public static copyDraft(
    {
      draftId,
      requestBody,
    }: {
      draftId: string;
      requestBody: DraftCopyRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppOrderRequisition> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/app/drafts/{draftId}",
      path: {
        draftId: draftId,
      },
      body: requestBody,
      mediaType: "application/json",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns Unit OK
   * @throws ApiError
   */
  public static delete(
    {
      draftId,
    }: {
      draftId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Unit> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/app/drafts/{draftId}",
      path: {
        draftId: draftId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppCustomer OK
   * @throws ApiError
   */
  public static saveOrUpdateCustomer(
    {
      id,
      requestBody,
    }: {
      id: string;
      requestBody: AppCustomer;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppCustomer> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/app/customers/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static deleteCustomer(
    {
      id,
    }: {
      id: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/app/customers/{id}",
      path: {
        id: id,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppVesselTokenShared OK
   * @throws ApiError
   */
  public static shareVesselToken(
    {
      id,
      requestBody,
    }: {
      id: string;
      requestBody: AppVesselTokenShareRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppVesselTokenShared> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/app/vessels/{id}/token-send",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppVesselTokenShared OK
   * @throws ApiError
   */
  public static newVesselToken(
    {
      id,
    }: {
      id: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppVesselTokenShared> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/app/vessels/{id}/token-refresh",
      path: {
        id: id,
      },
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppVesselToken OK
   * @throws ApiError
   */
  public static getVesselToken(
    {
      id,
    }: {
      id: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppVesselToken> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/vessels/{id}/token",
      path: {
        id: id,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppVesselToken OK
   * @throws ApiError
   */
  public static getPurchaserVesselToken(
    {
      id,
    }: {
      id: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppVesselToken> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/app/vessels/{id}/token",
      path: {
        id: id,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppOrderRequisition OK
   * @throws ApiError
   */
  public static rejectRequisition(
    {
      requisitionId,
      requestBody,
    }: {
      requisitionId: string;
      requestBody: AppRequisitionRejectRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppOrderRequisition> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/app/requisitions/{requisitionId}/reject",
      path: {
        requisitionId: requisitionId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppOrderRequisition OK
   * @throws ApiError
   */
  public static approveRequisition(
    {
      requisitionId,
      requestBody,
    }: {
      requisitionId: string;
      requestBody: AppRequisitionApproveRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppOrderRequisition> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/app/requisitions/{requisitionId}/approve",
      path: {
        requisitionId: requisitionId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppOrderRequisition OK
   * @throws ApiError
   */
  public static rejectRfqQuotation(
    {
      quotationId,
      requestBody,
    }: {
      quotationId: string;
      requestBody: AppQuotationRejectRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppOrderRequisition> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/app/quotations/{quotationId}/rfq-reject",
      path: {
        quotationId: quotationId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppOrderRequisition OK
   * @throws ApiError
   */
  public static approveRfqQuotation(
    {
      quotationId,
      requestBody,
    }: {
      quotationId: string;
      requestBody: AppQuotationApproveRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppOrderRequisition> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/app/quotations/{quotationId}/rfq-approve",
      path: {
        quotationId: quotationId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppOrderRequisition OK
   * @throws ApiError
   */
  public static rejectQuotation(
    {
      quotationId,
      requestBody,
    }: {
      quotationId: string;
      requestBody: AppQuotationRejectRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppOrderRequisition> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/app/quotations/{quotationId}/reject",
      path: {
        quotationId: quotationId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppOrderRequisition OK
   * @throws ApiError
   */
  public static approveQuotation(
    {
      quotationId,
      requestBody,
    }: {
      quotationId: string;
      requestBody: AppQuotationApproveRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppOrderRequisition> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/app/quotations/{quotationId}/approve",
      path: {
        quotationId: quotationId,
      },
      body: requestBody,
      mediaType: "application/json",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static addVesselsToPurchaser(
    {
      requestBody,
    }: {
      requestBody: AppPurchaserVesselRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/app/purchasers/vessels",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppOrderRequisition OK
   * @throws ApiError
   */
  public static closeOrder(
    {
      orderId,
    }: {
      orderId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppOrderRequisition> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/app/orders/{orderId}/closures",
      path: {
        orderId: orderId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppOrderRequisition OK
   * @throws ApiError
   */
  public static cancelOrder(
    {
      orderId,
    }: {
      orderId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppOrderRequisition> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/app/orders/{orderId}/cancellations",
      path: {
        orderId: orderId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppOrderRequisition OK
   * @throws ApiError
   */
  public static createOrder(
    {
      requestBody,
    }: {
      requestBody: AppOrderCreationRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppOrderRequisition> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/app/orders",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static addUsersToVessels(
    {
      id,
      formData,
    }: {
      id: string;
      formData?: {
        file: Blob;
      };
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/app/fleets/{id}/users",
      path: {
        id: id,
      },
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns User OK
   * @throws ApiError
   */
  public static addUserToFleet(
    {
      id,
      requestBody,
    }: {
      id: string;
      requestBody: AddUserToFleetRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/app/fleets/{id}/user",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns User OK
   * @throws ApiError
   */
  public static removeUserFromFleet(
    {
      id,
      requestBody,
    }: {
      id: string;
      requestBody: RemoveUserFromFleetRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/app/fleets/{id}/user",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppOrderRequisition OK
   * @throws ApiError
   */
  public static create(
    {
      requestBody,
    }: {
      requestBody: DraftRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppOrderRequisition> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/app/drafts",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns ApiListAppVessel OK
   * @throws ApiError
   */
  public static getAllVesselsForCurrentUser(
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ApiListAppVessel> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/vessels",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppPurchaserConfiguration OK
   * @throws ApiError
   */
  public static getPurchaser(
    {
      purchaserId,
    }: {
      purchaserId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppPurchaserConfiguration> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/purchasers/{purchaserId}/configuration",
      path: {
        purchaserId: purchaserId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppPurchasersFleets OK
   * @throws ApiError
   */
  public static getAllFleetsWithPurchasers(
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppPurchasersFleets> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/purchasers/fleets",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppPortsResponse OK
   * @throws ApiError
   */
  public static getPorts(
    {
      fleetId,
    }: {
      fleetId?: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppPortsResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/ports",
      query: {
        fleetId: fleetId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns ApiListAppProductSku OK
   * @throws ApiError
   */
  public static getAvailableProducts(
    {
      orderId,
    }: {
      orderId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ApiListAppProductSku> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/orders/{orderId}/productVariants",
      path: {
        orderId: orderId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns binary OK
   * @throws ApiError
   */
  public static getPurchaseOrderAsMtml(
    {
      orderId,
    }: {
      orderId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/orders/{orderId}/mtml",
      path: {
        orderId: orderId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static getPurchaseOrder(
    {
      orderId,
      outputFormat = "JSON",
    }: {
      orderId: string;
      outputFormat?: "JSON" | "XML";
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/orders/{orderId}",
      path: {
        orderId: orderId,
      },
      query: {
        outputFormat: outputFormat,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns binary OK
   * @throws ApiError
   */
  public static exportOrderRequisitionByIdToExcel(
    {
      id,
    }: {
      id: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/orderRequisitions/{id}/excel",
      path: {
        id: id,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppOrderRequisition OK
   * @throws ApiError
   */
  public static getOrderRequisitionById(
    {
      id,
    }: {
      id: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppOrderRequisition> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/orderRequisitions/{id}",
      path: {
        id: id,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppOrderRequisitionResponse OK
   * @throws ApiError
   */
  public static getAllOrderRequisitions(
    {
      cursor = "",
      vesselId,
      status,
      statuses,
      closed,
    }: {
      cursor?: string;
      vesselId?: string;
      status?:
        | "CANCELLED"
        | "PENDING_CANCELLATION"
        | "SUPPLIER_ACKNOWLEDGED"
        | "SUPPLIER_CONFIRMED"
        | "SUPPLIER_PENDING"
        | "SUPPLIER_REJECTED"
        | "SUPPLIER_DELIVERED"
        | "CLOSED"
        | "PENDING_PURCHASER_APPROVAL"
        | "PURCHASER_REJECTED"
        | "SUPPLIER_QUOTE_PENDING"
        | "SUPPLIER_QUOTE_ACKNOWLEDGED"
        | "SUPPLIER_QUOTE_PROVIDED"
        | "SUPPLIER_QUOTE_REJECTION_ACKNOWLEDGED"
        | "QUOTE_EXPIRED"
        | "QUOTE_REJECTED"
        | "DRAFT_CREATED"
        | "DRAFT_DELETED";
      statuses?: Array<
        | "CANCELLED"
        | "PENDING_CANCELLATION"
        | "SUPPLIER_ACKNOWLEDGED"
        | "SUPPLIER_CONFIRMED"
        | "SUPPLIER_PENDING"
        | "SUPPLIER_REJECTED"
        | "SUPPLIER_DELIVERED"
        | "CLOSED"
        | "PENDING_PURCHASER_APPROVAL"
        | "PURCHASER_REJECTED"
        | "SUPPLIER_QUOTE_PENDING"
        | "SUPPLIER_QUOTE_ACKNOWLEDGED"
        | "SUPPLIER_QUOTE_PROVIDED"
        | "SUPPLIER_QUOTE_REJECTION_ACKNOWLEDGED"
        | "QUOTE_EXPIRED"
        | "QUOTE_REJECTED"
        | "DRAFT_CREATED"
        | "DRAFT_DELETED"
      >;
      closed?: boolean;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppOrderRequisitionResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/orderRequisitions",
      query: {
        cursor: cursor,
        vesselId: vesselId,
        status: status,
        statuses: statuses,
        closed: closed,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppOrderRequisition OK
   * @throws ApiError
   */
  public static getOrderRequisitionById1(
    {
      id,
    }: {
      id: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppOrderRequisition> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/orderRequisition/{id}",
      path: {
        id: id,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns ApiListAppFleet OK
   * @throws ApiError
   */
  public static getAllFleetsForCurrentUser(
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ApiListAppFleet> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/fleets",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns ApiListAppCustomer OK
   * @throws ApiError
   */
  public static getCustomersForCurrentUser(
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ApiListAppCustomer> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/customers",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
