import { useEffect } from "react";

import { OpenAPI as AttachmentsOpenAPI } from "@web/attachments";
import {
  APP_VERSION_HTTP_HEADER_NAME,
  UNLEASH_ATTRIBUTES_HTTP_HEADER_NAME,
  useFeatureFlagsManagement,
} from "@web/common";

import { OpenAPI } from "../typegens";

const VITE_GIT_HASH = (import.meta.env.VITE_GIT_HASH as string) || "";

export const ApiHeadersSetup = () => {
  const { unleashContextFlagProperties } = useFeatureFlagsManagement();

  useEffect(() => {
    const unleashOverrideAttributesHeader = {
      [UNLEASH_ATTRIBUTES_HTTP_HEADER_NAME]: JSON.stringify(unleashContextFlagProperties),
    };
    const appVersionHeader = {
      [APP_VERSION_HTTP_HEADER_NAME]: VITE_GIT_HASH,
    };
    OpenAPI.HEADERS = {
      ...unleashOverrideAttributesHeader,
      ...appVersionHeader,
    };
    AttachmentsOpenAPI.HEADERS = unleashOverrideAttributesHeader;
  }, [unleashContextFlagProperties]);

  return null;
};
