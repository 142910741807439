import _debounce from "lodash/debounce";
import { useCallback, useEffect } from "react";
import { UseFormGetValues, UseFormWatch } from "react-hook-form";

import { OrderInfoFormInputs, OrderInfoFormValues } from "@web/common/pages";
import { WithRequiredProperty } from "@web/utils";

type Params = {
  getValues: UseFormGetValues<OrderInfoFormInputs>;
  watch: UseFormWatch<OrderInfoFormInputs>;
  onFormValuesChanged?: (formData: WithRequiredProperty<OrderInfoFormValues, "subject">) => void;
  getTransformedFormValues: (
    formData: OrderInfoFormInputs
  ) => WithRequiredProperty<OrderInfoFormValues, "subject">;
};

export const useNotifyFormValuesChanged = ({
  watch,
  getValues,
  onFormValuesChanged,
  getTransformedFormValues,
}: Params) => {
  const notifyFormValuesChanged = useCallback(() => {
    if (onFormValuesChanged) {
      const filtered = getTransformedFormValues(getValues());
      onFormValuesChanged(filtered);
    }
  }, [getValues, onFormValuesChanged, getTransformedFormValues]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const notifyFormValuesChangedDebounced = useCallback(
    _debounce(() => {
      notifyFormValuesChanged();
    }, 50),
    [notifyFormValuesChanged]
  );

  const abortFormValuesChangedDebounced = useCallback(
    () => notifyFormValuesChangedDebounced.cancel(),
    [notifyFormValuesChangedDebounced]
  );

  useEffect(() => {
    // Push the initial form values
    notifyFormValuesChangedDebounced();
    return () => abortFormValuesChangedDebounced();
  }, [abortFormValuesChangedDebounced, notifyFormValuesChangedDebounced]);

  useEffect(() => {
    if (onFormValuesChanged) {
      const { unsubscribe } = watch(() => {
        notifyFormValuesChangedDebounced();
      });
      return () => {
        unsubscribe();
        abortFormValuesChangedDebounced();
      };
    }
  }, [
    abortFormValuesChangedDebounced,
    watch,
    notifyFormValuesChangedDebounced,
    onFormValuesChanged,
  ]);
};
