import { SearchIcon, XIcon } from "@heroicons/react/solid";
import { useState } from "react";

import VesselIcon from "@web/common/icons/VesselIcon.svg";
import { CircledImage, Heading, Input, Paragraph, vesselPalette } from "@web/ui";
import { useDebounce } from "@web/utils";

import { updatePucharserVessels } from "src/api/updatePurchaserVessels";
import { AppPurchaserConfiguration, AppVessel } from "src/typegens";

type VesselsListProps = {
  search: string;
  closeModal: () => void;
  inputValue: string;
  purchaserVessels: AppPurchaserConfiguration;
};

const VesselsList: React.FC<VesselsListProps> = ({ search, inputValue, purchaserVessels }) => {
  const currentVessels = purchaserVessels.assignedVessels.map((a) => a.id);

  const handleVesselSelect = (selectedVessel: AppVessel) => {
    updatePucharserVessels({
      purchaserId: purchaserVessels.purchaserId,
      vesselsIds: [...currentVessels, selectedVessel.id],
    }).then(() => {
      window.location.reload();
    });
  };

  const searchPhrase = search.toLowerCase();

  const vessels = purchaserVessels.allowedVessels;
  const filtered =
    search === "" ? vessels : vessels.filter((v) => v.name.toLowerCase().includes(searchPhrase));

  return (
    <>
      {filtered.length > 0 && (
        <ul
          className="max-h-80 overflow-y-auto overflow-x-hidden"
          data-testid="selectVessel_container"
        >
          {filtered.map((vessel) => (
            <div
              className="items-center flex p-2 hover:bg-neutral_200 rounded-md cursor-pointer"
              data-testid="selectVessel_vessel"
              key={vessel.id}
              onClick={() => handleVesselSelect(vessel)}
            >
              <div className="flex-col">
                <CircledImage
                  Icon={VesselIcon}
                  size={5}
                  hashKey={vessel.id}
                  palette={vesselPalette}
                />
              </div>
              <div className="flex-col ml-2">
                <Paragraph size="200" color="text-textIcon-blackPrimary">
                  {vessel.name}
                </Paragraph>
                <Paragraph size="300">IMO: {vessel.imoNumber}</Paragraph>
              </div>
            </div>
          ))}
        </ul>
      )}
      {filtered.length === 0 && (
        <div className="m-3">
          <Paragraph
            size="100"
            color="text-textIcon-blackPrimary"
          >{`No results for "${inputValue}"`}</Paragraph>
          <Paragraph size="200" className="mt-2" color="text-textIcon-whiteDisabled">
            Try a different search term or check the spelling.
          </Paragraph>
        </div>
      )}
    </>
  );
};

const DEBOUNCE_MS = 150;

type Props = {
  closeModal: () => void;
  purchaserVessels: AppPurchaserConfiguration;
};

export const AssignVesselModal: React.FC<Props> = ({ closeModal, purchaserVessels }) => {
  const [inputValue, setInputValue] = useState("");
  const searchValue = useDebounce(inputValue, DEBOUNCE_MS);

  return (
    <div className="inline-block bg-neutral_0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle">
      <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-textIcon-whiteDisabled hover:text-textIcon-blackSecondary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
          onClick={closeModal}
        >
          <XIcon className="h-5 w-5 text-textIcon-whiteDisabled" aria-hidden="true" />
        </button>
      </div>
      <div className="flex flex-col w-130 px-3 py-4">
        <Heading size="200">Search and Assign Vessel</Heading>
        <Paragraph size="300" color="text-textIcon-blackSecondary" className="mt-2">
          Shows a list of vessels that are not yet assigned to a purchaser
        </Paragraph>
        <Input
          prefixIcon={
            <SearchIcon className="h-4 w-4 text-textIcon-blackSecondary" aria-hidden="true" />
          }
          placeholder="Search vessel"
          value={inputValue}
          onChange={(v) => setInputValue(v.target.value)}
        />
        <VesselsList
          search={searchValue}
          closeModal={closeModal}
          inputValue={inputValue}
          purchaserVessels={purchaserVessels}
        />
      </div>
    </div>
  );
};
