import { DEFAULT_CURRENCY_CODE } from "@web/common/config/constants";
import { BasketExtraItemEntry, ProductSku } from "@web/common/models";
import { Money } from "@web/models";
import { isDefined } from "@web/utils";

import { BasketEntry, BasketRequisitionRfqEntry } from "../models/basket";

export const getBasketGrandTotal = (lineItems: BasketEntry[]): Money => ({
  amount: roundMoneyAmount(
    lineItems.reduce((total, lineItem) => total + getLineItemTotalAmount(lineItem), 0)
  ),
  currencyCode: lineItems[0] ? getProductCurrency(lineItems[0].sku) : DEFAULT_CURRENCY_CODE,
});

export const roundEntityQuantity = (entityQuantity: number): number =>
  Math.round(entityQuantity * 100) / 100;

export const roundMoneyAmount = (amount: number): number => Math.round(amount * 100) / 100;

export const getTotalQuantity = (
  lineItems: BasketEntry[],
  rfqItems: BasketRequisitionRfqEntry[],
  extraItems: BasketExtraItemEntry[]
) =>
  lineItems.reduce((total, lineItem) => total + lineItem.quantity, 0) +
  rfqItems.reduce((total, rfqItem) => total + rfqItem.quantity, 0) +
  extraItems.reduce((total, extraItem) => total + extraItem.quantity, 0);

export const getSalesEntityQuantity = (sku: ProductSku) =>
  roundEntityQuantity(sku.salesEntityQuantity);

export const getSingleEntityPrice = (sku: ProductSku): Money => ({
  amount: getSingleEntityPriceAmount(sku),
  currencyCode: sku.price.costPrice.currencyCode,
});

export const getSingleEntityPriceAmount = (sku: ProductSku): number =>
  roundMoneyAmount(sku.price.costPrice.amount);

export const getLineItemEntityQuantity = (lineItem: BasketEntry): number =>
  getTotalEntityQuantity(getSalesEntityQuantity(lineItem.sku), lineItem.quantity);

export const getTotalEntityQuantity = (salesEntityQuantity: number, itemQuantity: number): number =>
  roundEntityQuantity(salesEntityQuantity * itemQuantity);

/**
 * Do not use this to calculate any totals, as this value is rounded
 * and will lead to rounding errors when multiplied by quantity.
 * Recommended usage: only to represent the price of a single sales entity.
 * This replaces the `salesEntityPrice` field.
 */
export const getSalesEntityPrice = (sku: ProductSku): Money => {
  const singleEntityPriceAmount = getSingleEntityPriceAmount(sku);
  const salesEntityQuantity = getSalesEntityQuantity(sku);
  return {
    amount: roundMoneyAmount(singleEntityPriceAmount * salesEntityQuantity),
    currencyCode: getProductCurrency(sku),
  };
};

/**
 * @return Returned value is not rounded and should be used only for calculations,
 *         e.g. calculating grand total.
 */
export const getLineItemTotalAmount = (
  lineItem: BasketEntry,
  overloadedItemQuantity?: number
): number => {
  const singleEntityPriceAmount = getSingleEntityPriceAmount(lineItem.sku);
  const lineItemEntityQuantity = getTotalEntityQuantity(
    lineItem.sku.salesEntityQuantity,
    isDefined(overloadedItemQuantity) ? overloadedItemQuantity : lineItem.quantity
  );
  return singleEntityPriceAmount * lineItemEntityQuantity;
};

export const getLineItemTotal = (
  lineItem: BasketEntry,
  overloadedItemQuantity?: number
): Money => ({
  currencyCode: getProductCurrency(lineItem.sku),
  amount: roundMoneyAmount(getLineItemTotalAmount(lineItem, overloadedItemQuantity)),
});

export const getProductCurrency = (sku: ProductSku) => sku && sku.price.costPrice.currencyCode;

export const getMinimumOrderQuantity = (sku: ProductSku) =>
  sku.about?.generalInformation?.minimumOrderQuantity || 1;

export const getMinimumQuantityNumber = (sku: ProductSku) => {
  const minimumQuantity = getMinimumOrderQuantity(sku);
  const salesEntityQuantity = getSalesEntityQuantity(sku);
  return Math.round(minimumQuantity / salesEntityQuantity);
};

export const getLowestPriceSku = (skus: ProductSku[]): ProductSku | undefined => {
  const salesEntityPrices = skus.map((sku) => getSalesEntityPrice(sku).amount);
  const lowestPriceSkuIndex = salesEntityPrices.indexOf(Math.min(...salesEntityPrices));
  const lowestPriceSku = skus[lowestPriceSkuIndex];
  return lowestPriceSku ? { ...lowestPriceSku } : undefined;
};
