import { DefaultError, useMutation } from "@tanstack/react-query";

import { getPurchaserVesselToken } from "../api/getPurchaserVesselToken";
import { AppVesselToken } from "../typegens";

export const usePurchaserVesselTokenMutation = (options = {}) => {
  return useMutation<AppVesselToken, DefaultError, string>({
    mutationKey: ["purchaserVesselToken"],
    mutationFn: getPurchaserVesselToken,
    ...options,
  });
};
