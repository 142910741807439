import { ReactElement } from "react";

import { string2color } from "../../utils";
import { portPalette } from "../../utils/palettes";

interface Props {
  Icon: (({ className }: { className: string }) => ReactElement) | string;
  size: number;
  color?: string;
  hashKey?: string;
  palette?: Array<string>;
  className?: string;
  style?: object;
}

export const CircledImage: React.FC<Props> = ({
  Icon,
  color: colorProp,
  size,
  hashKey,
  palette = portPalette,
  className,
  style,
}) => {
  if (!hashKey) {
    return null;
  }
  const imageSize = size - 2;
  const color = colorProp ? colorProp : string2color(hashKey, palette);

  return (
    <div
      style={style}
      className={`rounded-full w-${size} h-${size} inline-flex justify-center items-center shrink-0 mr-2 ${color} ${className}`}
    >
      {typeof Icon === "string" ? (
        <img src={Icon} className={`w-${imageSize} h-${imageSize} ${className} text-neutral_0`} />
      ) : (
        <Icon className={`w-${imageSize} h-${imageSize} ${className} text-neutral_0`} />
      )}
    </div>
  );
};
