import { UserIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";
import { UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Money, Port } from "@web/models";
import { CircledImage, Input, Label, Paragraph, SummaryBoxUI } from "@web/ui";
import { formatMoney } from "@web/utils";

import AnchorWhite from "../icons/AnchorWhite.svg";
import VesselIcon from "../icons/VesselIcon.svg";
import { Vessel } from "../models/Vessel";
import { SupplierInformation } from "../network/model";
// Don't shorten this import, otherwise circular dep will exist
import { CreationMode } from "../pages/OrderInfo/models";

interface Props {
  creationMode: CreationMode;
  email?: string;
  grandTotal: Money;
  hasFMSIntegration?: boolean;
  isAutoApprovedRequisition?: boolean;
  isLoading: boolean;
  name?: string;
  nrLineItems: number;
  nrRfqItems: number;
  nrExtraItems: number;
  onPoNumberChange: (po: string) => void;
  onSubmit: () => void;
  poNumber?: string;
  poNumberError?: string;
  port?: Port | null;
  isPoNumberRequired?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  registrationName?: string;
  isSubmitDisabled?: boolean;
  validationErrorMessage?: string;
  vessel?: Pick<Vessel, "name" | "id" | "contactInformation">;
  renderAttentionInfo?: ({ className }: { className: string }) => React.ReactElement | null;
  orderName?: string;
  supplier: SupplierInformation | undefined;
}

const AutoGeneratedPO = () => {
  return (
    <div
      className="flex flex-col gap-1 rounded-md p-4 bg-neutral_200 mt-5"
      data-testid="autoGeneratedPO"
    >
      <Label size="200">PO Number</Label>
      <Label size="300" color="text-textIcon-blackSecondary">
        The PO number will be automatically generated in FMS.
      </Label>
    </div>
  );
};

const DEBOUNCE_TIME_MS = 500;
const debouncedSetDisplayName = debounce((disName, fn) => fn(disName), DEBOUNCE_TIME_MS);

const POInput = ({
  error,
  register,
  registrationName,
  required,
}: {
  error?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any;
  registrationName: string;
  required: boolean;
}) => {
  return (
    <div className="bg-neutral_200 rounded-md p-4 text-textIcon-blackPrimary mt-5">
      <Input
        {...register(registrationName, {
          required: {
            value: required,
            message: "Please enter PO number",
          },
          maxLength: {
            value: 16,
            message: "Field must not exceed 16 characters",
          },
        })}
        data-testid="poInput"
        errorMessage={error}
        label="PO Number"
        withBorder
      />
      <Label
        size="300"
        color="text-textIcon-blackSecondary"
        className={classnames({ "mt-3": !error, "mt-6": error }, "block")}
      >
        Enter an internal PO Number from your FMS
      </Label>
    </div>
  );
};

export const SummaryBox = ({
  register,
  registrationName = "",
  creationMode,
  email = "",
  grandTotal,
  hasFMSIntegration,
  isLoading,
  name = "",
  nrLineItems,
  nrRfqItems,
  onSubmit,
  poNumberError,
  isPoNumberRequired = false,
  port,
  isSubmitDisabled = false,
  validationErrorMessage,
  isAutoApprovedRequisition,
  vessel,
  renderAttentionInfo,
  orderName,
  nrExtraItems,
  supplier,
}: Props) => {
  const { t } = useTranslation();
  const [displayName, setDisplayName] = useState(name);

  const selectCTALabel = useCallback(
    (creationMode: CreationMode) => {
      switch (creationMode) {
        case "ORDER_CREATION":
        case "PURCHASER_ORDER_CREATION":
          return t("common.components.summaryBox.ctaOrder");
        case "REQUISITION_CREATION":
          return isAutoApprovedRequisition
            ? t("common.components.summaryBox.ctaOrder")
            : t("common.components.summaryBox.ctaRequisition");
        case "REQUISITION_APPROVAL":
          return t("common.components.summaryBox.ctaOrder");
        case "QUOTATION_CREATION":
          return t("common.components.summaryBox.quotation.ctaQuotation");
        case "QUOTATION_APPROVAL":
          return t("common.components.summaryBox.ctaOrder");
      }
    },
    [isAutoApprovedRequisition, t]
  );

  useEffect(() => debouncedSetDisplayName(name, setDisplayName), [name]);

  return (
    <>
      <SummaryBoxUI
        email={email}
        vatPrice={formatMoney({
          amount: 0,
          currencyCode: grandTotal.currencyCode,
        })}
        nrLineItems={nrLineItems}
        nrRfqItems={nrRfqItems}
        nrExtraItems={nrExtraItems}
        onSubmit={onSubmit}
        isSubmitDisabled={isSubmitDisabled || isLoading}
        isLoading={isLoading}
        totalPrice={formatMoney(grandTotal)}
        totalItemsPrice={formatMoney(grandTotal)}
        showCtaButton={true}
        ctaLabel={selectCTALabel(creationMode)}
        poInput={
          hasFMSIntegration ? (
            <AutoGeneratedPO />
          ) : (
            <POInput
              register={register}
              registrationName={registrationName}
              error={poNumberError}
              required={isPoNumberRequired}
            />
          )
        }
        validationErrorMessage={validationErrorMessage}
        renderAttentionInfo={renderAttentionInfo}
        orderName={orderName}
        supplier={supplier}
      />
      <div className="rounded-lg bg-neutral_200 px-6 py-6 mt-4">
        <Paragraph size="200" color="text-textIcon-blackSecondary" className="uppercase">
          {t("common.components.summaryBox.portTitle")}
        </Paragraph>
        <div className="flex pt-4 pb-5 items-center">
          <CircledImage Icon={AnchorWhite} size={6} hashKey={port?.id} />
          <Paragraph size="200" color="text-textIcon-blackSecondary">
            {port?.locationCode}
          </Paragraph>
          <Paragraph size="200" className="pl-2">
            {port?.name}
          </Paragraph>
        </div>

        <Paragraph size="200" color="text-textIcon-blackSecondary" className="uppercase">
          {t("common.components.summaryBox.vesselTitle")}
        </Paragraph>
        <div className="flex pt-4 pb-5 items-center">
          <CircledImage Icon={VesselIcon} size={6} hashKey={vessel?.id} />
          <Paragraph size="200" className="pl-2">
            {vessel?.name}
          </Paragraph>
        </div>

        <hr className="pb-2" />

        <Paragraph size="200" color="text-textIcon-blackSecondary" className="uppercase">
          {creationMode === "REQUISITION_CREATION"
            ? t("common.components.summaryBox.internalRequester")
            : t("common.components.summaryBox.contactTitle")}
        </Paragraph>
        <div className="flex pt-4 pb-5 items-center">
          <CircledImage Icon={UserIcon} size={6} hashKey={displayName} />
          <Paragraph size="200" className="pl-2" data-testid="summaryBox_requesterName">
            {displayName}
          </Paragraph>
        </div>
      </div>
    </>
  );
};
