import { TrashIcon } from "@heroicons/react/outline";
import { PlusIcon } from "@heroicons/react/solid";
import classnames from "classnames";

import { ProductSku } from "@web/common/models";
import { IconButton, Tooltip } from "@web/ui";

import { ProductItem, ProductItemReplacement } from "../network/model";
import { ProductItemsTableRow } from "./ProductItemsTableRow";

export type OnChooseReplacement = ({
  replacementForId,
  replacementId,
}: {
  replacementForId: string;
  replacementId: string | undefined;
}) => void;

type Props = {
  productItems: Array<ProductItem>;
  replacements?: Record<string, Array<ProductItemReplacement>>;
  lineThrough?: boolean;
  sideNote?: string;
  sideNoteVariant?: "success" | "danger";
  className?: string;
  openProductDetailsModal?: (productSku: ProductSku) => void;
  onChooseReplacement?: OnChooseReplacement;
  preserveRightMargin?: boolean;
};

export const ProductItemsTable = ({
  productItems,
  lineThrough = false,
  sideNote,
  sideNoteVariant,
  className = "",
  openProductDetailsModal,
  replacements,
  onChooseReplacement,
  preserveRightMargin = false,
}: Props) => {
  const hasItemWithLineItemNumber = !!productItems.find((item) => item.lineNumber);
  const canDigitalAssetsColumnBeShown = !!productItems.find(
    (item) => item.skuDetails.attachments && item.skuDetails.attachments.length > 0
  );

  // TODO #2830: Fix not rounded tr on firefox
  return (
    <table
      className={classnames(className, "border-separate w-full pt-1", {
        "pl-5": hasItemWithLineItemNumber,
        "pr-5": preserveRightMargin,
      })}
      style={{ borderSpacing: "0 0.25rem" }}
      data-testid="productItemsTable"
    >
      <tbody>
        {productItems.map((item: ProductItem) => {
          const replacement = replacements?.[item.skuDetails.id]?.[0];
          const renderRowActions =
            replacement && onChooseReplacement
              ? () => (
                  <div
                    className="absolute left-full top-1/2 pl-0.5"
                    style={{ transform: "translateY(-50%)" }}
                  >
                    <Tooltip
                      tooltipText={
                        replacement.isChosen ? "Reject Replacement" : "Accept Replacement"
                      }
                    >
                      <IconButton
                        size="small"
                        shape="circle"
                        variant={replacement.isChosen ? "danger" : "primary"}
                        label={replacement.isChosen ? "Reject Replacement" : "Accept Replacement"}
                        Icon={replacement.isChosen ? TrashIcon : PlusIcon}
                        onClick={() =>
                          onChooseReplacement({
                            replacementForId: item.skuDetails.id,
                            replacementId: replacement.isChosen
                              ? undefined
                              : replacement.skuDetails.id,
                          })
                        }
                      />
                    </Tooltip>
                  </div>
                )
              : undefined;
          return (
            <ProductItemsTableRow
              key={item.skuDetails.id}
              productItem={item}
              lineThrough={lineThrough}
              sideNote={sideNote}
              sideNoteVariant={sideNoteVariant}
              openProductDetailsModal={openProductDetailsModal}
              hasDigitalAssetsColumn={canDigitalAssetsColumnBeShown}
              replacement={replacement}
              replacementSideNote={
                replacement?.isChosen ? "Proposed Replacement" : "Proposed Replacement - Rejected"
              }
              replacementSideNoteVariant={replacement?.isChosen ? "success" : "danger"}
              renderRowActions={renderRowActions}
            />
          );
        })}
      </tbody>
    </table>
  );
};
