import { ChevronRightIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { InvoiceAccountUI } from "@web/common";
import ContactCard from "@web/common/layouts/ContactCard";
import { OrderReqService } from "@web/common/services/OrderRequisition";
import { Heading, Label, Loading, Paragraph, RegularButton, RfqBadge, Tooltip } from "@web/ui";

import routes from "../../config/routes";
import { useOrderRequisitionQuery } from "../../hooks/useOrderRequisitionQuery";

const OrderSuccess: React.FC = () => {
  const { orderId } = useParams() as { orderId: string };
  const navigate = useNavigate();
  const orderQuery = useOrderRequisitionQuery({ orderId: orderId });
  const { t } = useTranslation();

  if (orderQuery.isFetching || orderQuery.isPending) {
    return <Loading />;
  }

  if (orderQuery.status === "success") {
    const order = orderQuery.data;
    const isRequestedQuotation = orderQuery.data.type === "QUOTATION";

    const nrLineItems = order.items.length;
    const nrExtraItems = order.extraItems.length;

    const orderTotalGrossAmountFormatted = OrderReqService.getTotalGrossAmount(orderQuery.data);
    return (
      <div className="flex flex-col">
        <div className="max-w-3xl mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:px-8 text-center my-8">
          <div className="max-w-xl">
            <Label size="300" color="text-primaryDefault" className="uppercase">
              {isRequestedQuotation
                ? t("pages.orderConfirmation.rfqConfirmation")
                : t("pages.orderConfirmation.orderConfirmation")}
            </Label>
            {!isRequestedQuotation && (
              <Paragraph
                size="300"
                color="text-textIcon-blackSecondary"
                className="pt-2 pb-4"
                data-testid="orderSuccess_orderId"
              >
                {order.id}
              </Paragraph>
            )}
            <Heading size="100">
              {t(
                `${
                  isRequestedQuotation
                    ? "pages.orderConfirmation.rfqHeadline"
                    : "pages.orderConfirmation.headline"
                }`
              )}
            </Heading>
            {/* TODO: #6144 Paragraph should not wrap other divs */}
            <Paragraph size="200" color="text-textIcon-blackSecondary" className="pt-4">
              {!isRequestedQuotation ? (
                // TODO: #6144 This should be wrapped in one of typography components
                <div>
                  {t("pages.orderConfirmation.copy", {
                    email: order.requesterInformation?.email,
                  })}{" "}
                  {/* TODO: #6144 This should be wrapped in one of typography components */}
                  <br /> {t("pages.orderConfirmation.copySecond")}
                </div>
              ) : (
                // TODO: #6144 This should be wrapped in one of typography components
                <div>
                  {t("pages.orderConfirmation.rfqCopy", {
                    email: order.requesterInformation?.email,
                  })}
                </div>
              )}
            </Paragraph>
          </div>
          {/* Billing information */}
          {!!order.invoiceAccount && (
            <div className="flex flex-col items-start mt-6">
              <Paragraph size="300" color="text-textIcon-blackSecondary" className="mb-4">
                {t("pages.orderConfirmation.billingInformation")}
              </Paragraph>
              <InvoiceAccountUI value={order.invoiceAccount} />
            </div>
          )}

          <div className="mt-4">
            <div>
              {isRequestedQuotation ? (
                <dl className="grid text-sm pt-6 text-left">
                  <div className="bg-neutral_100 p-4 border-neutral_300 rounded-md mt-6 w-full">
                    <div>
                      <Label size="300">{t("pages.requisition.requestedItems")}</Label>
                    </div>
                    <div className="flex items-center justify-between">
                      <Label size="300">
                        {t("pages.orderConfirmation.nrItems", {
                          count: order.rfqItems?.length,
                        })}
                      </Label>
                      <Label size="300">
                        <div className="text-right">
                          <Tooltip tooltipText={t("common.rfqTooltip")}>
                            <RfqBadge className="inline-flex" />
                          </Tooltip>
                        </div>
                      </Label>
                    </div>
                  </div>
                </dl>
              ) : (
                <>
                  <div className="flex flex-col gap-3 mt-5 text-left">
                    <div>
                      <Label size="200" color="text-textIcon-blackSecondary">
                        {order.supplierInformation.name} - Catalog&nbsp;Items
                      </Label>
                      <div className="flex justify-between items-center">
                        <Paragraph size="200" data-testid="orderSuccess_nrItems">
                          {nrLineItems} {`Item${nrLineItems > 1 ? "s" : ""}`}
                        </Paragraph>
                        <Paragraph size="200">{orderTotalGrossAmountFormatted}</Paragraph>
                      </div>
                    </div>
                    {nrExtraItems > 0 && (
                      <div>
                        <Label size="200" color="text-textIcon-blackSecondary">
                          Extra Items
                        </Label>
                        <div className="flex justify-between items-center">
                          <Paragraph size="200" data-testid="orderSuccess_nrExtraItems">
                            {nrExtraItems} {`Item${nrExtraItems > 1 ? "s" : ""}`}
                          </Paragraph>
                          <Paragraph size="200">TBD</Paragraph>
                        </div>
                      </div>
                    )}
                    {nrLineItems > 0 && nrExtraItems > 0 && (
                      <div>
                        <Label size="200">Total Items</Label>
                        <div className="flex justify-between items-center">
                          <Paragraph size="200" data-testid="orderSuccess_nrTotalItems">
                            {nrLineItems + nrExtraItems}{" "}
                            {`Item${nrLineItems + nrExtraItems > 1 ? "s" : ""}`}
                          </Paragraph>
                          <Paragraph size="200">{orderTotalGrossAmountFormatted}</Paragraph>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}

              {!isRequestedQuotation && (
                <>
                  <dl className="border-t space-y-5 mt-6 mb-6 border-neutral_300 text-sm pt-6">
                    <div className="flex justify-between">
                      <Label size="300" color="text-textIcon-blackSecondary">
                        {t("pages.orderConfirmation.salesTax0")}
                      </Label>
                      <Label size="300" color="text-textIcon-blackSecondary">
                        {OrderReqService.getAmountZeroInOrderCurrency(order)}
                      </Label>
                    </div>
                  </dl>

                  <dl className="border-t border-neutral_300 text-sm pt-6">
                    <div className="flex justify-between">
                      <Label size="200">{t("pages.orderConfirmation.total")}</Label>
                      <dd>
                        <Heading
                          size="300"
                          /* color="text-textIcon-700" */
                          // TODO #6444: Fixme, there is no such color in tailwind config
                        >
                          {isRequestedQuotation ? "-" : orderTotalGrossAmountFormatted}
                        </Heading>
                      </dd>
                    </div>
                  </dl>
                  {!!order.storageLabel && (
                    <dl className="border-t space-y-5 mb-6 border-neutral_300 text-sm pt-6">
                      <div className="flex justify-between">
                        <Label size="300" color="text-textIcon-blackSecondary">
                          {t("pages.orderConfirmation.storageLabel")}
                        </Label>
                      </div>
                      <div className="flex justify-between">{order.storageLabel}</div>
                    </dl>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="max-w-lg mx-auto my-2">
          <RegularButton
            variant="primary"
            size="large"
            label={t("pages.orderConfirmation.ctaOverview")}
            TrailingIcon={ChevronRightIcon}
            onClick={() => navigate(`${routes.orders}`)}
          />
        </div>

        <ContactCard />
      </div>
    );
  }

  return <div>undefined state</div>;
};

export default OrderSuccess;
