import { useEffect, useRef } from "react";

import { useAuth0WithRedirect } from "@web/common";

export const PendoInitializer = () => {
  const { user } = useAuth0WithRedirect();

  const userEmail = user?.email;
  const userName = user?.name;

  const pendoInitialized = useRef<boolean>(false);
  const isPendoInitialized = pendoInitialized.current === true;

  useEffect(() => {
    if (isPendoInitialized || !userEmail || !userName) {
      return;
    }

    pendo.initialize({
      visitor: {
        id: userEmail,
        email: userEmail,
        full_name: userName,
      },
    });
    pendoInitialized.current = true;
  }, [isPendoInitialized, userEmail, userName]);

  return null;
};
