import { useState } from "react";

import { OrderInfo, OrderInfoFormValues } from "@web/common";
import FormLayout from "@web/common/layouts/FormLayout";
import { OrderRequisition } from "@web/common/network/model";

import { useApproveQuotationMutation } from "src/hooks/useApproveQuotationMutation";

import { toQuotationApproveItem } from "./utils";

interface Props {
  quotationId: string;
  orderRequisition: OrderRequisition;
}

export const QuotationApproval = ({ quotationId, orderRequisition }: Props) => {
  const { mutate, isPending } = useApproveQuotationMutation();

  const [formOrderName, setFormOrderName] = useState<string | undefined>(orderRequisition.subject);

  const submitOrderHandler = async (orderSummary: OrderInfoFormValues) => {
    // This guard is only to make the compiler happy, without major refactor of OrderInfo component.
    // In reality this will never happen thanks to the validation set up in OrderInfo component.
    if (!orderSummary.agentInformation) {
      throw Error("Agent info not defined");
    }
    if (!orderSummary.deliveryDate) {
      throw Error("Delivery date not defined");
    }

    mutate({
      id: quotationId,
      requestBody: {
        agent: orderSummary.agentInformation,
        consolidated: false,
        deliveryDate: orderSummary.deliveryDate,
        orderNotes: orderSummary.orderNotes,
        storageLabel: orderSummary.storageLabel,
        customerOrderId: orderSummary.customerOrderId,
        items: orderRequisition.items.map((item) => toQuotationApproveItem(item)),
        subject: orderSummary.subject,
      },
    });
  };

  return (
    <FormLayout>
      <OrderInfo
        creationMode={"QUOTATION_APPROVAL"}
        grandTotal={orderRequisition.totalGrossAmount}
        loading={isPending}
        nrLineItems={orderRequisition.items.length}
        nrRfqItems={0}
        nrExtraItems={0}
        orderRequisition={orderRequisition}
        port={orderRequisition.port}
        submitForm={submitOrderHandler}
        vessel={orderRequisition.vessel}
        withOrderComment
        initialOrderName={orderRequisition.subject}
        updateOrderNameInState={setFormOrderName}
        orderName={formOrderName}
        supplier={undefined}
        defaultAgentInformation={orderRequisition.agentInformation}
      />
    </FormLayout>
  );
};
