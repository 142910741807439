import { SvgIconComponent } from "@mui/icons-material";
import classnames from "classnames";

import { Label } from "../../Typography";
import { SearchNavLink } from "../SearchNavLink";

interface Props {
  text: string;
  // TODO #12908: Remove legacy type once we get rid of the old icons
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>> | SvgIconComponent;
  pathname: string;
  search?: string;
  className?: string;
}

export const SectionHeaderLink: React.FC<Props> = ({
  className = "",
  text,
  pathname,
  search,
  Icon,
}) => (
  <SearchNavLink
    className={classnames(
      className,
      "flex items-center gap-2 rounded-lg py-2 px-3 hover:bg-neutral_300"
    )}
    to={{ pathname, search }}
    activeClassName="bg-neutral_300"
  >
    <Icon className="h-5 w-5 text-textIcon-blackPrimary" aria-hidden />
    <Label size="100" color="text-textIcon-blackPrimary" className="capitalize">
      {text}
    </Label>
  </SearchNavLink>
);
