import { z } from "zod";

import { GatherOutFlowSchema } from "./GatherOutFlowSchema";

export const GatherOutConfigSchema = z.object({
  gatherOutFlow: GatherOutFlowSchema,
  basketId: z.string().optional(),
  vesselToken: z.string(),
  timestamp: z.string(),
  submitReturnToUrl: z.string(),
  cancelReturnToUrl: z.string(),
});

export type GatherOutConfig = z.infer<typeof GatherOutConfigSchema>;
