import { ProductItemsTable } from "@web/common/components/ProductItemsTable";
import { ChangeType, ProductChange, ProductItem } from "@web/common/network";
import { Label } from "@web/ui";

import { SingleChangeType } from "./components";

interface Props {
  allowedChangeTypes: ChangeType[];
  unchangedItems: ProductItem[];
  changedItems: ProductChange[];
}

export const OrderChanges = ({ allowedChangeTypes, unchangedItems, changedItems }: Props) => {
  return (
    <>
      {allowedChangeTypes.map((changeType) => {
        return (
          <SingleChangeType
            key={changeType}
            changeType={changeType}
            changedItems={changedItems.filter((change) => change.changeType === changeType)}
          />
        );
      })}
      {unchangedItems.length > 0 && (
        <>
          <Label size="300" className="text-textIcon-whiteDisabled block mt-4">
            NO CHANGE
          </Label>
          <ProductItemsTable productItems={unchangedItems} />
        </>
      )}
    </>
  );
};
