import {
  MailIcon,
  OfficeBuildingIcon,
  PhoneIcon,
  UserCircleIcon,
  XIcon,
} from "@heroicons/react/solid";

import { AgentInformation } from "@web/models";
import { Label, Paragraph } from "@web/ui";

import { RequesterInformation } from "../../../network/model";

interface Props {
  closeModal: () => void;
  agentInformation?: AgentInformation;
  requesterInformation?: RequesterInformation;
  vesselName: string;
}

export const ContactInfoModal: React.FC<Props> = ({
  closeModal,
  agentInformation,
  requesterInformation,
  vesselName,
}) => {
  return (
    <div className="inline-block bg-neutral_0 rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all align-middle">
      <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-textIcon-whiteDisabled hover:text-textIcon-blackSecondary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
          onClick={closeModal}
        >
          <span className="sr-only">Close modal</span>
          <XIcon className="h-5 w-5 text-textIcon-whiteDisabled" aria-hidden="true" />
        </button>
      </div>
      <div className="flex flex-col items-center text-center w-96">
        {!!requesterInformation && (
          <>
            <div className="sm:mt-3 sm:mb-2 sm:text-center">
              <Paragraph size="100">Internal Requester</Paragraph>
            </div>
            <UserCircleIcon className="w-5 text-textIcon-blackSecondary mt-1" />
            <div className="mt-2 max-w-sm">
              <Paragraph size="200" color="text-textIcon-blackSecondary" className="break-words">
                {requesterInformation.name}
              </Paragraph>
              <Paragraph size="300" color="text-neutral_700" className="break-words">
                {vesselName}
              </Paragraph>
            </div>
            <div className="mt-4">
              <hr />
              <div className="flex items-center mt-4 mb-5 max-w-sm">
                <MailIcon className="w-5 text-textIcon-whiteDisabled mr-2 flex-none" />
                <Label
                  size="200"
                  color="text-textIcon-blackSecondary"
                  className="min-w-0 break-words"
                >
                  {requesterInformation.email}
                </Label>
              </div>
            </div>
          </>
        )}
        {!!agentInformation && (
          <>
            <div className="sm:mt-3 sm:mb-2 sm:text-center">
              <Paragraph size="100">Agent</Paragraph>
            </div>
            <OfficeBuildingIcon className="w-5 text-textIcon-blackSecondary mt-1" />
            <div className="mt-2 max-w-sm">
              <Paragraph size="200" color="text-textIcon-blackSecondary" className="break-words">
                {agentInformation.firstName} {agentInformation.lastName}
              </Paragraph>
              <Paragraph size="300" color="text-neutral_700" className="break-words">
                {agentInformation.company}
              </Paragraph>
            </div>
            <div className="mt-4 flex flex-col items-center mb-5">
              <hr className="self-stretch" />
              <div className="flex items-center mt-4 gap-2 max-w-sm">
                <PhoneIcon className="w-5 text-textIcon-whiteDisabled flex-none" />
                <Label
                  size="200"
                  color="text-textIcon-blackSecondary"
                  className="min-w-0 break-words"
                >
                  {agentInformation.phoneNumber}
                </Label>
              </div>
              <div className="flex items-center mt-4 gap-2 max-w-sm">
                <MailIcon className="w-5 text-textIcon-whiteDisabled flex-none" />
                <Label
                  size="200"
                  color="text-textIcon-blackSecondary"
                  className="min-w-0 break-words"
                >
                  {agentInformation.email}
                </Label>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
