import { Route, Routes as RoutesSwitch, useNavigate } from "react-router-dom";

import { NotFoundErrorPage } from "@web/ui";

import "./App.css";
import routes from "./config/routes";
import ManageCustomers from "./pages/ManageCustomers";
import ManageFleets from "./pages/ManageFleets";
import ManagePurchaserVessels from "./pages/ManagePurchaserVessels";
import ManagePurchasers from "./pages/ManagePurchasers";
import ManageVessels from "./pages/ManageVessels";
import OrderRequisitionDetails from "./pages/OrderRequisitionDetails";
import OrderSuccess from "./pages/OrderSuccess";
import { OrderSummaryPage } from "./pages/OrderSummary";
import Orders from "./pages/Orders";

export const Routes = () => {
  const navigate = useNavigate();

  return (
    <RoutesSwitch>
      <Route path={routes.orders} element={<Orders />} />
      <Route path={`${routes.orderSuccess}/:orderId`} element={<OrderSuccess />} />
      <Route path={routes.manageVessels} element={<ManageVessels />} />
      <Route path={routes.manageCustomers} element={<ManageCustomers />} />
      <Route path={routes.manageUsers} element={<ManagePurchasers />} />
      <Route path={`${routes.manageUsers}/:id`} element={<ManagePurchaserVessels />} />
      <Route path={routes.manageFleets} element={<ManageFleets />} />
      <Route
        path={`${routes.orderDetails}/:orderRequisitionId`}
        element={<OrderRequisitionDetails />}
      />
      <Route
        path={`${routes.orderDetails}/:orderRequisitionId/approve`}
        element={<OrderSummaryPage />}
      />
      <Route
        path="*"
        element={<NotFoundErrorPage onButtonClick={() => navigate(routes.orders)} />}
      />
    </RoutesSwitch>
  );
};
