import { SvgIconComponent } from "@mui/icons-material";
import classnames from "classnames";
import { forwardRef } from "react";

import { Label, LabelProps } from "../../atoms";

type ChipProps = {
  label: string;
  size: ChipSize;
  onClick: () => void;
  selected?: boolean;
  className?: string;
  // TODO #12908: Remove legacy types once we get rid of the old icons, applies to both LeadingIcon and TrailingIcon
  LeadingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>> | SvgIconComponent;
  TrailingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>> | SvgIconComponent;
  "data-testid"?: string;
};

type ChipSize = "large" | "small" | "xsmall";

type ChipState = "selected" | "default";

const stateToChipClassNames: Readonly<{ [key in ChipState]: string }> = {
  default: "bg-neutral_0 border-primaryDefault hover:bg-primaryBackground",
  selected: "bg-primaryDefault border-primaryDefault",
};

const stateToChipTextColorClassNames: Readonly<{ [key in ChipState]: LabelProps["color"] }> = {
  default: "text-primaryDefault",
  selected: "text-neutral_0",
};

const sizeToChipClassNames: Readonly<{ [key in ChipProps["size"]]: string }> = {
  // 11px padding (instead of 12px) accounts for 1px border for an element with non-restricted width
  large: "h-[40px] min-h-[40px] max-h-[40px] px-[11px] gap-[8px]",
  // 8px padding (instead of 9px) accounts for 1px border for an element with non-restricted width
  small: "h-[34px] min-h-[34px] max-h-[34px] px-[8px] gap-[6px]",
  // 4px padding (instead of 5px) accounts for 1px border for an element with non-restricted width
  xsmall: "h-[24px] min-h-[24px] max-h-[24px] px-[4px] gap-[4px]",
};

const sizeToIconClassNames: Readonly<{ [key in ChipProps["size"]]: string }> = {
  large: "w-[20px] h-[20px]",
  small: "w-[18px] h-[18px]",
  xsmall: "w-[16px] h-[16px]",
};

const sizeToTypographySize: Readonly<{ [key in ChipProps["size"]]: LabelProps["size"] }> = {
  large: "100",
  small: "200",
  xsmall: "200",
};

export const Chip = forwardRef<HTMLButtonElement, ChipProps>(
  (
    {
      label,
      size,
      onClick,
      selected = false,
      LeadingIcon,
      TrailingIcon,
      className = "",
      "data-testid": testId = "",
      ...rest
    },
    forwardedRef
  ) => {
    const chipState: ChipState = selected ? "selected" : "default";

    const chipStateClassNames = stateToChipClassNames[chipState];
    const chipStateTextColorClassNames = stateToChipTextColorClassNames[chipState];
    const chipSizeClassNames = sizeToChipClassNames[size];
    const iconSizeClassNames = sizeToIconClassNames[size];
    const typographySize = sizeToTypographySize[size];

    return (
      <button
        ref={forwardedRef}
        className={classnames(
          "inline-flex justify-center items-center",
          "w-fit min-w-fit max-w-[240px]",
          "rounded-full border",
          "focus:outline-none focus-visible:ring-2 ring-offset-2 ring-offset-white ring-primaryDefault",
          "overflow-hidden whitespace-nowrap leading-none",
          chipStateClassNames,
          chipStateTextColorClassNames,
          chipSizeClassNames,
          className
        )}
        onClick={onClick}
        {...(testId ? { "data-testid": testId } : {})}
        {...rest}
      >
        {LeadingIcon && <LeadingIcon className={iconSizeClassNames} />}
        {/* Color prop is empty to overwrite the default color of the Label component, so the color can be inherited from the parent Chip component */}
        <Label
          size={typographySize}
          color=""
          className={
            LeadingIcon && TrailingIcon
              ? "max-w-[160px] truncate ..."
              : "max-w-[176px] truncate ..."
          }
        >
          {label}
        </Label>
        {TrailingIcon && <TrailingIcon className={iconSizeClassNames} />}
      </button>
    );
  }
);
Chip.displayName = "Chip";
