import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import { ForwardedRef, Fragment, ReactNode, forwardRef } from "react";

import { Label, Paragraph } from "../../atoms/Typography";

export type OptionType<T = string> =
  | {
      key?: string;
      value: T;
      label: string;
      prefix?: ReactNode;
      LeadingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    }
  | Record<string, never>;

export type SelectProps<T = string> = {
  options: OptionType<T>[];
  value?: OptionType<T>;
  onChange: (value: OptionType<T>) => void;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  errorMessage?: string;
  isCompactVersion?: boolean;
  selectButtonHeight?: string;
  dropdownWidth?: string;
  dropdownHPosition?: string;
  allowMultilineOptions?: boolean;
  testId?: string;
  vAlignTo?: "top" | "bottom";
};

function SelectWithRef<T>(
  {
    options,
    value,
    onChange,
    label,
    disabled,
    placeholder,
    className,
    errorMessage,
    isCompactVersion,
    dropdownWidth = "w-full",
    dropdownHPosition = "inset-x-0",
    allowMultilineOptions = false,
    testId = "select",
    vAlignTo = "bottom",
  }: SelectProps<T>,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <div className={classnames("flex flex-col gap-2", className)} data-testid={testId} ref={ref}>
      {!!label && (
        <Label size="200" color="text-textIcon-blackSecondary">
          {label}
        </Label>
      )}
      <Listbox value={value} onChange={onChange} disabled={disabled}>
        {({ open }) => (
          <>
            <div className="relative flex items-center content-center w-full h-6.5">
              <Listbox.Button
                className={classnames(
                  "py-2 text-left focus:outline-none text-sm leading-5 font-normal flex justify-between shadow-sm w-full h-full border border-solid rounded-md",
                  {
                    "cursor-default bg-neutral_200": disabled,
                    "cursor-pointer bg-neutral_0": !disabled,
                    "text-textIcon-blackSecondary": disabled,
                    "border-dangerDefault": !!errorMessage,
                  }
                )}
                data-testid={`${testId}_button`}
              >
                <span
                  className={classnames("flex items-center truncate pr-6 pl-3", {
                    "text-textIcon-blackDisabled": !value,
                  })}
                >
                  {value?.prefix && value.prefix}
                  {value?.LeadingIcon && (
                    <div className="mr-2">
                      <value.LeadingIcon
                        className="h-5 w-5 text-textIcon-blackSecondary"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  <span>{value ? value.label : placeholder}</span>
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronDownIcon className="w-5 text-textIcon-whiteDisabled" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className={classnames(
                    {
                      "bottom-[calc(100%+5px)]": vAlignTo === "top",
                      "top-[calc(100%+5px)]": vAlignTo === "bottom",
                    },
                    isCompactVersion ? `max-h-13` : `max-h-96`,
                    "m-0 absolute z-10 bg-neutral_0 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm p-1 overscroll-y-auto",
                    dropdownHPosition,
                    dropdownWidth
                  )}
                  data-testid={`${testId}_options`}
                >
                  {options.map((option, index) => (
                    <Listbox.Option
                      key={option.key || `${option.value}`}
                      className={({ active }) =>
                        classnames(
                          active ? "text-neutral_0 bg-primaryDefault" : "",
                          "cursor-pointer select-none relative py-2 pl-3 pr-6.5 rounded"
                        )
                      }
                      value={option}
                      data-testid={`${testId}_option${index}`}
                    >
                      {({ selected, active }) => (
                        <div className="flex items-center">
                          {option.prefix && option.prefix}
                          {option.LeadingIcon && (
                            <div className="mr-2">
                              <option.LeadingIcon
                                className={classnames(
                                  "h-5 w-5 block",
                                  active ? "text-neutral_0" : "text-textIcon-blackSecondary"
                                )}
                                aria-hidden="true"
                              />
                            </div>
                          )}
                          <span
                            className={classnames("block", {
                              "text-neutral_0 bg-primaryDefault": active,
                              "text-textIcon-blackPrimary": !active,
                              truncate: !allowMultilineOptions,
                            })}
                          >
                            {option.label}
                          </span>
                          {selected && (
                            <span
                              className={classnames(
                                active ? "text-neutral_0" : "text-primaryDefault",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          )}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {/* error label goes here */}
      {!!errorMessage && (
        <Paragraph size="200" color="text-dangerDefault" data-testid={`${testId}_error`}>
          {errorMessage}
        </Paragraph>
      )}
    </div>
  );
}

export const Select = forwardRef(SelectWithRef) as <T>(
  props: SelectProps<T> & { ref?: ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof SelectWithRef>;
