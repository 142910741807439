import { PlusIcon, XIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useModalContext } from "@web/common/contexts/ModalContext";
import VesselIcon from "@web/common/icons/VesselIcon.svg";
import { Vessel } from "@web/common/models/Vessel";
import {
  CircledImage,
  Heading,
  IconButton,
  LinkButton,
  Loading,
  RegularButton,
  vesselPalette,
} from "@web/ui";

import { updatePucharserVessels } from "src/api/updatePurchaserVessels";
import { AssignVesselModal } from "src/components/modals/AssignVesselModal";
import InviteVesselModal from "src/components/modals/InviteVesselModal";
import routes from "src/config/routes";
import { usePurchaserVesselsQuery } from "src/hooks/usePurchaserVesselsQuery";

import ManageTable from "../../components/tables/ManageTable";
import ManageTableEmpty from "../../components/tables/ManageTableEmpty";
import ManageTableHeaders from "../../components/tables/ManageTableHeaders";
import { useFleetsQuery } from "../../hooks/useFleetsQuery";
import BaseLayout from "../../layouts/Base";
import { AppFleet } from "../../typegens";

interface ManageVesselRowProps {
  vessel: Vessel;
  fleets: AppFleet[];
  handleOnRemoveClick: (vessel: Vessel) => void;
  handleOnInviteClick: (vessel: Vessel) => void;
}

const ManageVesselRow: React.FC<ManageVesselRowProps> = ({
  vessel,
  fleets,
  handleOnRemoveClick,
  handleOnInviteClick,
}) => {
  const { t } = useTranslation();
  const fleet = fleets.find((fleet) => fleet.id === vessel.fleetId);

  return (
    <tr>
      <td className="pl-5 py-4 h-full">
        <div className="flex items-center">
          <CircledImage Icon={VesselIcon} size={7} hashKey={vessel.id} palette={vesselPalette} />
          <div className="ml-4">
            <div className="text-sm font-medium text-textIcon-blackPrimary">{vessel.name}</div>
            <div className="text-sm text-textIcon-blackSecondary">{vessel.imoNumber}</div>
          </div>
        </div>
      </td>
      <td className="pl-5 py-4 max-w-0">
        <div className="text-sm text-textIcon-blackPrimary whitespace-nowrap truncate">
          {fleet?.name}
        </div>
        <div className="text-sm text-textIcon-blackSecondary whitespace-nowrap truncate">
          {fleet?.customerName}
        </div>
      </td>
      <td className="pl-5 py-4 whitespace-nowrap">
        <div className="text-sm text-textIcon-blackPrimary">{vessel.contactInformation?.name}</div>
        <div className="text-sm text-textIcon-blackSecondary">
          {vessel.contactInformation?.email}
        </div>
      </td>
      <td className="px-5 py-4 whitespace-nowrap">
        <span className="inline-flex min-h-full">
          <RegularButton
            variant="primary"
            size="large"
            label={t("pages.manageVessels.vesselsList.invite")}
            onClick={() => handleOnInviteClick(vessel)}
          />
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <LinkButton
          size="large"
          variant="danger"
          label={t("pages.manageVessels.vesselsList.remove")}
          onClick={() => handleOnRemoveClick(vessel)}
        />
      </td>
    </tr>
  );
};

const ManagePurchaserVessels = () => {
  const { openModal, closeModal } = useModalContext();
  const { t } = useTranslation();
  const url = window.location.pathname;
  const purchaserId = url.split("/").pop();
  const navigate = useNavigate();

  const purchaserVesselsQuery = usePurchaserVesselsQuery(purchaserId as string);
  const fleetsQuery = useFleetsQuery();

  if (!(purchaserVesselsQuery.isSuccess && fleetsQuery.isSuccess)) {
    return <Loading />;
  }
  const purchaserVessels = purchaserVesselsQuery.data;
  const fleets = fleetsQuery.data;

  const headerNames = [
    t("pages.manageVessels.vesselsList.name"),
    t("pages.manageVessels.vesselsList.fleet"),
    t("pages.manageVessels.vesselsList.contact"),
    "",
    "",
  ];

  const currentVessels = purchaserVessels.assignedVessels.map((a) => a.id);

  const onRemoveItem = (vessel: Vessel) => {
    const vessels = currentVessels.filter((e) => e !== vessel.id);
    updatePucharserVessels({
      purchaserId: purchaserVessels.purchaserId,
      vesselsIds: vessels,
    }).then(() => {
      window.location.reload();
    });
  };

  const handleOnAddClick = () => {
    openModal(<AssignVesselModal closeModal={closeModal} purchaserVessels={purchaserVessels} />);
  };

  const handleOnInviteClick = (vessel: Vessel) => {
    openModal(<InviteVesselModal vessel={vessel} />);
  };

  const handleOnRemoveClick = (vessel: Vessel) => {
    onRemoveItem(vessel);
  };

  return (
    <BaseLayout noSidebar>
      <IconButton
        variant="secondary"
        shape="circle"
        size="large"
        label="Go back"
        Icon={XIcon}
        onClick={() => {
          navigate(routes.manageUsers);
        }}
      />
      <div className="rounded-lg bg-neutral_0 shadow flex flex-col mt-2">
        <div className="p-5 border-b border-neutral_300 flex justify-between items-center">
          <Heading size="300">{purchaserVessels.purchaserName}</Heading>
          <RegularButton
            variant="primary"
            size="large"
            width="content"
            label={t("pages.manageVessels.vesselsList.assignVessel")}
            LeadingIcon={PlusIcon}
            onClick={handleOnAddClick}
          />
        </div>
        {purchaserVessels.assignedVessels.length > 0 ? (
          <ManageTable
            colgroup={
              <colgroup>
                <col span={1} className="w-1/2" />
                <col span={1} className="w-2/3" />
                <col span={3} className="w-1/3" />
              </colgroup>
            }
            headerRows={<ManageTableHeaders headerNames={headerNames} />}
            bodyRows={purchaserVessels.assignedVessels.map((purchaser) => (
              <ManageVesselRow
                key={purchaser.id}
                vessel={purchaser}
                fleets={fleets}
                handleOnRemoveClick={handleOnRemoveClick}
                handleOnInviteClick={handleOnInviteClick}
              />
            ))}
          />
        ) : (
          <ManageTableEmpty
            header={t("pages.manageVessels.vesselsList.noVesselsAssignHeader")}
            message={t("pages.manageVessels.vesselsList.noVesselsAssignMessage")}
          />
        )}
      </div>
    </BaseLayout>
  );
};

export default ManagePurchaserVessels;
