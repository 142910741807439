import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import { Fragment, forwardRef } from "react";

import { Label, Paragraph } from "@web/ui";

export type OptionType =
  | {
      value: string;
      label: string;
    }
  | Record<string, never>;

interface Props {
  options: OptionType[];
  value?: OptionType;
  onChange: (value: OptionType) => void;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  errorMessage?: string;
  isCompactVersion?: boolean;
  selectButtonHeight?: string;
  dropdownWidth?: string;
  dropdownHPosition?: string;
  testId?: string;
}

export const Select = forwardRef<HTMLDivElement, Props>(
  (
    {
      options,
      value,
      onChange,
      label,
      disabled,
      placeholder,
      className,
      errorMessage,
      isCompactVersion,
      dropdownWidth = "w-full",
      dropdownHPosition = "inset-x-0",
      testId = "select",
    },
    ref
  ) => {
    return (
      <div className={className} ref={ref}>
        {!!label && (
          <Label size="200" color="text-textIcon-blackSecondary">
            {label}
          </Label>
        )}
        <Listbox value={value} onChange={onChange} disabled={disabled}>
          {({ open }) => (
            <>
              <div
                className={`relative flex items-center content-center shadow-sm w-full border border-solid rounded-md h-6.5
                ${disabled ? "bg-neutral_200 cursor-default" : "cursor-pointer"}
              `}
              >
                <Listbox.Button
                  className={`py-2 text-left focus:outline-none text-sm leading-5 font-light flex justify-between w-full h-full ${
                    disabled ? "cursor-default" : ""
                  }`}
                  data-testid={`${testId}_button`}
                >
                  <span className="block truncate pr-6 pl-3">
                    {value ? value.label : placeholder}
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronDownIcon
                      className="h-5 w-5 text-textIcon-whiteDisabled"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    static
                    className={`${
                      isCompactVersion ? `max-h-13` : `max-h-96`
                    } absolute ${dropdownHPosition} top-0 bottom-auto translate-y-6 z-10 mt-1 ${dropdownWidth} bg-neutral_0 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm p-1 overscroll-y-auto`}
                    data-testid={`${testId}_options`}
                  >
                    {options.map((option, index) => (
                      <Listbox.Option
                        key={`${option.value}`}
                        className={({ active }) =>
                          classnames(
                            active ? "text-neutral_0 bg-primaryDefault" : "",
                            "cursor-pointer select-none relative py-2 pl-3 pr-6.5 rounded"
                          )
                        }
                        value={option}
                        data-testid={`${testId}_option${index}`}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classnames(
                                active ? "text-neutral_0 bg-primaryDefault" : "",
                                "block truncate"
                              )}
                            >
                              {option.label}
                            </span>
                            {selected && (
                              <span
                                className={classnames(
                                  active ? "text-neutral_0" : "text-primaryDefault",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        {/* error label goes here */}
        {!!errorMessage && (
          <Paragraph size="200" color="text-dangerDefault" className="h-4">
            {errorMessage}
          </Paragraph>
        )}
      </div>
    );
  }
);
Select.displayName = "Select";
