import { Paragraph } from "@web/ui";

interface Props {
  header: string;
  message: string;
}

const ManageTableEmpty: React.FC<Props> = ({ header, message }) => {
  return (
    <div className="p-5 text-center">
      <Paragraph size="100" className="m-4 mt-5">
        {header}
      </Paragraph>
      <Paragraph size="200" className="text-textIcon-whiteDisabled m-4 mb-5">
        {message}
      </Paragraph>
    </div>
  );
};

export default ManageTableEmpty;
