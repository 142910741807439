import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useSystemMessage } from "src/hooks/useSystemMessage";

import routes from "../config/routes";
import { ApiResponse } from "../store";
import { AppOrderRequisition } from "../typegens";

export const useSuccessCreationCallback = () => {
  const navigate = useNavigate();
  const { setSystemMessage } = useSystemMessage();

  return useCallback(
    (data: ApiResponse<AppOrderRequisition>) => {
      if ("error" in data) {
        setSystemMessage({
          type: "failure",
          message: "There was an error while processing your request",
        });
      }

      if ("data" in data) {
        navigate(`${routes.orderSuccess}/${data.data.id}`);
      }
    },
    [navigate, setSystemMessage]
  );
};
