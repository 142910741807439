import { ExclamationIcon } from "@heroicons/react/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { DefaultError, useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";

import { Vessel } from "@web/common/models/Vessel";
import { Input, Paragraph, RegularButton } from "@web/ui";

import { inviteVessel } from "src/api/inviteVessel";
import { useSystemMessage } from "src/hooks/useSystemMessage";
import { ApiResponse } from "src/store";
import { AppVesselTokenShared } from "src/typegens/models/AppVesselTokenShared";

import { ShareLinkButton } from "./ShareLinkButton";
import { InviteVesselForm, InviteVesselSchema } from "./schema";

interface Props {
  vessel: Vessel;
}

export const InviteVesselModal: React.FC<Props> = ({ vessel }) => {
  const { setSystemMessage } = useSystemMessage();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: vessel.contactInformation?.email || "",
      name: "",
    },
    resolver: zodResolver(InviteVesselSchema),
  });

  const { mutate, isPending } = useMutation<
    ApiResponse<AppVesselTokenShared>,
    DefaultError,
    { id: string; form: InviteVesselForm }
  >({
    mutationKey: ["inviteVessel"],
    mutationFn: inviteVessel,
    onSuccess: () => {
      setSystemMessage({
        type: "success",
        message: "Email was sent.",
      });
    },
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: "Something went wrong.",
      });
    },
  });

  const onSubmit = async (form: InviteVesselForm) => {
    mutate({ id: vessel.id, form });
  };

  return (
    <div className="inline-block align-bottom bg-neutral_0 rounded-lg py-4 px-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div className="mt-3 text-center sm:mt-0 sm:text-left">
        <Paragraph size="100">Vessel specific URL</Paragraph>
        <div className="flex py-4">
          <ExclamationIcon className="w-5 text-dangerDefault" />
          <Paragraph size="200" color="text-textIcon-blackSecondary" className="ml-3">
            Only share this link with authorized personnel on this specific vessel
          </Paragraph>
        </div>
      </div>
      <div className="flex justify-between flex-col mb-4">
        <ShareLinkButton vesselId={vessel.id} />
      </div>
      <div className="mt-3 text-center sm:mt-0 sm:text-left">
        <Paragraph size="200">Send link by email</Paragraph>
      </div>
      <form>
        <div className="flex justify-between flex-col">
          <Controller
            name="email"
            control={control}
            render={({ field }) => {
              return (
                <Input
                  label="Vessel email"
                  placeholder="Vessel email"
                  errorMessage={errors.email?.message}
                  type="email"
                  {...field}
                />
              );
            }}
          />
          <RegularButton
            className="mt-1"
            variant="primary"
            size="large"
            label="Send link"
            loading={isPending}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </div>
  );
};
