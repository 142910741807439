import { Loading } from "@web/ui";

import { useCustomersQuery } from "../../hooks/useCustomersQuery";
import { useFleetsQuery } from "../../hooks/useFleetsQuery";
import { ManageFleets } from "./ManageFleets";

export const ManageFleetsQuery = () => {
  const fleetsQuery = useFleetsQuery();
  const customersQuery = useCustomersQuery();

  if (!(fleetsQuery.isSuccess && customersQuery.isSuccess)) {
    return <Loading />;
  }

  const fleets = fleetsQuery.data;
  const customers = customersQuery.data;

  return <ManageFleets fleets={fleets} customers={customers} />;
};
