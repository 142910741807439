import { XIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getOrderDate } from "@web/common";
import { Pill } from "@web/common/components";
import { OrderRequisition } from "@web/common/network/model";
import { IconButton, Paragraph } from "@web/ui";
import { LEGACY_formatDate } from "@web/utils";

import routes from "src/config/routes";

import RequisitionCTAButtons from "./RequisitionCTAButtons";

interface Props {
  requisition: OrderRequisition;
}

const RequisitionTopbar: React.FC<Props> = ({ requisition }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const requisitionDate = getOrderDate(requisition);

  return (
    <>
      <div className="w-full py-4 px-9 h-min flex-row flex items-center justify-between">
        <div className="flex items-center">
          <IconButton
            variant="secondary"
            shape="circle"
            size="large"
            label="Go back"
            Icon={XIcon}
            onClick={() => {
              navigate(routes.orders);
            }}
          />
          <div className="flex-col">
            <div className="flex align-start pl-5">
              <Paragraph size="100" weight="heavy">
                {requisition.id}
              </Paragraph>
              <div className="pl-4">
                <Pill status={requisition.status} />
              </div>
            </div>
            <div className="flex pt-2 pl-5">
              <Paragraph size="300" color="text-textIcon-blackSecondary">
                {t("pages.orders.requisition")}
              </Paragraph>
              {!!requisitionDate && (
                <Paragraph size="300" color="text-textIcon-blackSecondary" className="pl-4">
                  {t("common.topbar.orderDate")} {LEGACY_formatDate(requisitionDate)}
                </Paragraph>
              )}
            </div>
          </div>
        </div>
        <div>
          <RequisitionCTAButtons
            isRfQRequisition={requisition.isRfqRequisition}
            requisitionId={requisition.id}
            requisitionStatus={requisition.status}
            canDownloadExcel={requisition.canDownloadExcel}
            vesselId={requisition.vessel.id}
          />
        </div>
      </div>
      <hr />
    </>
  );
};
export default RequisitionTopbar;
