import { useParams } from "react-router-dom";

import { Loading } from "@web/ui";

import { useOrderRequisitionQuery } from "../../hooks/useOrderRequisitionQuery";
import { QuotationApproval, RequisitionApproval, RfqApproval } from "./components";

export const OrderSummaryPage = () => {
  const { orderRequisitionId } = useParams() as { orderRequisitionId: string };
  const orderQuery = useOrderRequisitionQuery(
    {
      orderId: orderRequisitionId,
    },
    { enabled: Boolean(orderRequisitionId) }
  );

  // TODO #12332: Upgrade & reuse in GatherOut
  if (!orderRequisitionId) {
    return null;
    // return (
    //   <GatherOrderCreation
    //     orderCreationItems={orderCreationItems}
    //     supplierId={supplierId}
    //     totalPrice={totalPrice}
    //   />
    // );
  }

  if (orderQuery.isPending || orderQuery.isFetching) {
    return <Loading />;
  }

  if (!orderQuery.isSuccess) {
    return null;
  }

  if (!orderQuery.data.port || !orderQuery.data.vessel) {
    throw Error("No port or vessel");
  }

  const orderRequisition = orderQuery.data;

  if (orderRequisition.type === "QUOTATION") {
    return (
      <QuotationApproval quotationId={orderRequisitionId} orderRequisition={orderRequisition} />
    );
  }

  if (orderRequisition.isRfqRequisition) {
    return <RfqApproval requisitionId={orderRequisitionId} orderRequisition={orderRequisition} />;
  }

  return (
    <>
      <RequisitionApproval requisitionId={orderRequisitionId} orderRequisition={orderRequisition} />
    </>
  );
};
