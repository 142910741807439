import queryString from "query-string";

import { GatherOutConfig, isRegatherOutFlow } from "@web/common/domain";

import { GatherOutFlow } from "../models";
import { isGatherOutFlowValid } from "./isGatherOutFlowValid";

const VESSEL_TOKEN_QUERY_PARAM = "vesselToken";
const GATHER_OUT_FLOW_QUERY_PARAM = "gatherOutFlow";
const GATHER_OUT_BASKET_ID_QUERY_PARAM = "gatherOutBasketId";
const GATHER_OUT_TIMESTAMP_PARAM = "gatherOutTimestamp";
const GATHER_OUT_SUBMIT_RETURN_TO_URL_PARAM = "gatherOutSubmitReturnToUrl";
const GATHER_OUT_CANCEL_RETURN_TO_URL_PARAM = "gatherOutCancelReturnToUrl";

export const getGatherOutUrl = ({
  baseUrl,
  vesselToken,
  gatherOutFlow,
  basketId,
  submitReturnToUrl,
  cancelReturnToUrl,
}: Pick<
  GatherOutConfig,
  "vesselToken" | "basketId" | "gatherOutFlow" | "submitReturnToUrl" | "cancelReturnToUrl"
> & {
  // Must start with http/https and must not have the search part
  baseUrl: string;
}): string => {
  const queryParams: Record<string, string | GatherOutFlow> = {
    [VESSEL_TOKEN_QUERY_PARAM]: vesselToken,
    [GATHER_OUT_FLOW_QUERY_PARAM]: gatherOutFlow,
    // We need this to initialize a new GatherOut session every time, because
    // in RegatherOut other params do not change, and we need to somehow
    // let the VA know that this is a possibly fresh set of data.
    [GATHER_OUT_TIMESTAMP_PARAM]: Date.now().toString(),
    [GATHER_OUT_SUBMIT_RETURN_TO_URL_PARAM]: encodeURIComponent(submitReturnToUrl),
    [GATHER_OUT_CANCEL_RETURN_TO_URL_PARAM]: encodeURIComponent(cancelReturnToUrl),
    ...(basketId ? { [GATHER_OUT_BASKET_ID_QUERY_PARAM]: basketId } : {}),
  };
  return `${baseUrl}/?${queryString.stringify(queryParams)}`;
};

export const decodeGatherOutUrl = (url: string): GatherOutConfig | undefined => {
  const parsedQuery = queryString.parseUrl(url).query;

  if (!parsedQuery) {
    return;
  }

  const vesselToken = Array.isArray(parsedQuery[VESSEL_TOKEN_QUERY_PARAM])
    ? parsedQuery[VESSEL_TOKEN_QUERY_PARAM][0]
    : parsedQuery[VESSEL_TOKEN_QUERY_PARAM];
  const gatherOutFlow = Array.isArray(parsedQuery[GATHER_OUT_FLOW_QUERY_PARAM])
    ? parsedQuery[GATHER_OUT_FLOW_QUERY_PARAM][0]
    : parsedQuery[GATHER_OUT_FLOW_QUERY_PARAM];
  const basketId =
    (Array.isArray(parsedQuery[GATHER_OUT_BASKET_ID_QUERY_PARAM])
      ? parsedQuery[GATHER_OUT_BASKET_ID_QUERY_PARAM][0]
      : parsedQuery[GATHER_OUT_BASKET_ID_QUERY_PARAM]) || undefined;
  const timestamp = Array.isArray(parsedQuery[GATHER_OUT_TIMESTAMP_PARAM])
    ? parsedQuery[GATHER_OUT_TIMESTAMP_PARAM][0]
    : parsedQuery[GATHER_OUT_TIMESTAMP_PARAM];

  const encodedSubmitReturnToUrl = Array.isArray(parsedQuery[GATHER_OUT_SUBMIT_RETURN_TO_URL_PARAM])
    ? parsedQuery[GATHER_OUT_SUBMIT_RETURN_TO_URL_PARAM][0]
    : parsedQuery[GATHER_OUT_SUBMIT_RETURN_TO_URL_PARAM];
  let submitReturnToUrl = "";
  if (encodedSubmitReturnToUrl) {
    submitReturnToUrl = decodeURIComponent(encodedSubmitReturnToUrl);
  }

  const encodedCancelReturnToUrl = Array.isArray(parsedQuery[GATHER_OUT_CANCEL_RETURN_TO_URL_PARAM])
    ? parsedQuery[GATHER_OUT_CANCEL_RETURN_TO_URL_PARAM][0]
    : parsedQuery[GATHER_OUT_CANCEL_RETURN_TO_URL_PARAM];
  let cancelReturnToUrl = "";
  if (encodedCancelReturnToUrl) {
    cancelReturnToUrl = decodeURIComponent(encodedCancelReturnToUrl);
  }

  if (
    !vesselToken ||
    !isGatherOutFlowValid(gatherOutFlow) ||
    (isRegatherOutFlow(gatherOutFlow) && !basketId) ||
    !timestamp ||
    !submitReturnToUrl ||
    !cancelReturnToUrl
  ) {
    return;
  }

  return {
    vesselToken,
    gatherOutFlow,
    basketId,
    timestamp,
    submitReturnToUrl,
    cancelReturnToUrl,
  };
};
