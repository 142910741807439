import { DefaultError, useMutation } from "@tanstack/react-query";
import type { UseMutationOptions } from "@tanstack/react-query/src/types";

import { updateRequisition } from "../api/updateRequisition";
import { UpdateRequisitionParams } from "../models/UpdateRequisitionParams";

export const useUpdateRequisitionMutation = (
  options: UseMutationOptions<unknown, DefaultError, UpdateRequisitionParams> = {}
) => {
  return useMutation<unknown, DefaultError, UpdateRequisitionParams>({
    mutationKey: ["updateRequisition"],
    mutationFn: updateRequisition,
    ...options,
  });
};
