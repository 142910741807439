import { QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Environment, FeatureFlagsManagementProvider } from "@web/common";
import { Auth0WithRedirectProvider } from "@web/common/contexts/Auth0WithRedirect";
import { MapHashLocationToRoute } from "@web/common/contexts/MapHashLocationToRoute";
import { ModalProvider } from "@web/common/contexts/ModalContext";
import { CustomerSupportWidget } from "@web/ui";

import { AppScope } from "src/typegens";

import { store } from "../store";
import { ApiHeadersSetup } from "./ApiHeadersSetup";
import AuthProvider from "./AuthProvider";
import { ErrorBoundary } from "./ErrorBoundary";
import { ExpirationController } from "./ExpirationController";
import { PendoInitializer } from "./PendoInitializer";
import { queryClient } from "./QueryClientCreator";
import SystemMessagesProvider from "./SystemMessagesProvider";
import { UnleashContextSetup } from "./UnleashContextSetup";

const VITE_AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID as string;
const VITE_AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN as string;
const VITE_AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE as string;
const VITE_UNLEASH_PROXY_URL = import.meta.env.VITE_UNLEASH_PROXY_URL as string;
const VITE_UNLEASH_PROXY_CLIENT_KEY = import.meta.env.VITE_UNLEASH_PROXY_CLIENT_KEY as string;
const VITE_UNLEASH_APP_ENVIRONMENT = import.meta.env.VITE_UNLEASH_APP_ENVIRONMENT as Environment;

const UNLEASH_APP_NAME = "app";
const UNLEASH_REFRESH_INTERVAL = 60;

interface AppProvidersProps {
  children: React.ReactNode;
}

const scopes: Record<AppScope, null> = {
  "read:fleets": null,
  "write:fleets": null,
  "read:vessels": null,
  "write:vessels": null,
  "read:customers": null,
  "write:customers": null,
  "read:quotations": null,
  "write:quotations": null,
  "read:requisitions": null,
  "read:vesselTokens": null,
  "write:requisitions": null,
  "read:purchase_orders": null,
  "read:userAssignments": null,
  "write:userAssignments": null,
  "create:purchase_orders": null,
};

const AppProviders: React.FC<AppProvidersProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <CustomerSupportWidget />
      <Provider store={store}>
        <ErrorBoundary navigate={navigate} pathname={location.pathname}>
          <QueryClientProvider client={queryClient}>
            <FeatureFlagsManagementProvider
              environment={VITE_UNLEASH_APP_ENVIRONMENT}
              unleashProxyUrl={VITE_UNLEASH_PROXY_URL}
              unleashAppName={UNLEASH_APP_NAME}
              unleashProxyClientKey={VITE_UNLEASH_PROXY_CLIENT_KEY}
              unleashRefreshInterval={UNLEASH_REFRESH_INTERVAL}
            >
              <ApiHeadersSetup />
              <Auth0WithRedirectProvider
                domain={VITE_AUTH0_DOMAIN}
                clientId={VITE_AUTH0_CLIENT_ID}
                audience={VITE_AUTH0_AUDIENCE}
                redirectUri={window.location.origin}
                scope={Object.keys(scopes).join(" ")}
                useRefreshTokens
              >
                <AuthProvider>
                  <MapHashLocationToRoute>
                    <ModalProvider>
                      <PendoInitializer />
                      <ExpirationController />
                      <UnleashContextSetup />
                      <SystemMessagesProvider>{children}</SystemMessagesProvider>
                    </ModalProvider>
                  </MapHashLocationToRoute>
                </AuthProvider>
              </Auth0WithRedirectProvider>
            </FeatureFlagsManagementProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </Provider>
    </>
  );
};
export default AppProviders;
